import React, { useEffect, useState } from 'react'
import { Switch, Text, View, useWindowDimensions } from 'react-native'
import { colors, TIMER_ACQUISITION_URL } from '../../../../../Helpers/variables'
import useAxios from '../../../../../Hooks/useAxios'
import { convertTimeWithSecs } from '../../../../../Helpers/timeConverter'
import { Ionicons } from '@expo/vector-icons'
import TimerModal from './TimerModal'
import useTimer from '../../../../../Hooks/useTimer'

export default function TimerControls() {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	const api = useAxios()
	const { timerProperties, setTimerProperties, handleTimerChange } = useTimer()

	const [modalVisible, setModalVisible] = useState(false)
	const [loading, setLoading] = useState(true)

	const getTimerAcquisition = async () => {
		const { data } = await api.get(TIMER_ACQUISITION_URL)
		if (data.length > 0) {
			setTimerProperties({
				isTimerChecked: data[0].timer_enabled_bool,
				timerMinutes: data[0].timer_value_integer,
				timerMinutesOriginal: data[0].timer_value_integer,
				isLoaded: true,
				countdownTimer: convertTimeWithSecs(data[0].timer_value_integer, 0)
			})
			handleTimerChange(data[0].timer_enabled_bool)
		} else
			setTimerProperties({
				isTimerChecked: timerProperties.isTimerChecked,
				timerMinutes: timerProperties.timerMinutes,
				timerMinutesOriginal: timerProperties.timerMinutesOriginal,
				isLoaded: true,
				countdownTimer: convertTimeWithSecs(timerProperties.timerMinutes, timerProperties.timerCountSeconds)
			})

		setLoading(false)
	}

	useEffect(() => {
		getTimerAcquisition()
		setLoading(false)
	}, [])

	//fix TimerControls, on mobile
	//fix TrackControls on smallscreen
	
	return loading ? (
		<View />
	) : (
		<View style={{ 
				flexDirection: isWideScreen ? 'row' : 'column',
				alignItems: isWideScreen ? 'center' : 'flex-start',
				width: '100%',
				paddingLeft: '8%'
			}}>
			{isWideScreen ? (
				<>
					<Switch
						trackColor={{ false: '#adadad', true: colors.secondary }}
						thumbColor={timerProperties.isTimerChecked ? colors.accent : '#ffffff'}
						onValueChange={() => {
							if (timerProperties.timerMinutes !== timerProperties.timerMinutesOriginal)
								handleTimerChange(!timerProperties.isTimerChecked)
							else handleTimerChange()
						}}
						value={timerProperties.isTimerChecked}
					/>
					<View style={{ alignItems: 'center', width: 80 }}>
						<Text style={{ color: 'white' }}>Timer: {timerProperties.countdownTimer}</Text>
					</View>
					{!timerProperties.isTimerChecked && (
						<Ionicons
							name="create"
							size={22}
							color={'white'}
							onPress={() => {
								setModalVisible(true)
							}}
						/>
					)}
					{modalVisible && (
						<TimerModal
							setModalVisible={setModalVisible}
							modalVisible={modalVisible}
							timerProperties={timerProperties}
							setTimerProperties={setTimerProperties}
						/>
					)}
				</>
			) : (
				<>
					<View style={{width:'100%'}}>
						<Switch
							style={{ fontSize: isSmallScreen ? 22 : 24, width : '100%' }}
							trackColor={{ false: '#adadad', true: colors.secondary }}
							thumbColor={timerProperties.isTimerChecked ? colors.accent : '#ffffff'}
							onValueChange={() => {
								if (timerProperties.timerMinutes !== timerProperties.timerMinutesOriginal)
									handleTimerChange(!timerProperties.isTimerChecked)
								else handleTimerChange()
							}}
							value={timerProperties.isTimerChecked}
						/>
					</View>
					<View style={{ 
							flexDirection: 'row', 
							justifyContent: 'space-between', 
							alignItems: 'center', 
							width: '100%'
						}}
					>
						<View>
							<Text style={{ color: 'white', marginLeft: 0 }}>Timer: {timerProperties.countdownTimer}</Text>
						</View>
						{!timerProperties.isTimerChecked && (
							<View>
								<Ionicons
									name="create"
									size={isSmallScreen ? 20 : 24}
									color={'white'}
									style={{ marginLeft: 0 }}
									onPress={() => {
										setModalVisible(true)
									}}
								/>
							</View>
						)}
					</View>
					{modalVisible && (
						<TimerModal
							setModalVisible={setModalVisible}
							modalVisible={modalVisible}
							timerProperties={timerProperties}
							setTimerProperties={setTimerProperties}
						/>
					)}
				</>

			)}
		</View>
	)
}
