import { getHeaderTitle } from '@react-navigation/elements'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { colors } from '../../Helpers/variables'
import AssignPlaylistUserScreen from '../../Screens/AdminAssignPlaylistUserScreen/AdminAssignPlaylistUserScreen'
import AdminInternalPlaylistContainerScreen from '../../Screens/AdminInternalPlaylistContainerScreen/AdminInternalPlaylistContainerScreen'
import AdminUserDeactivatedScreen from '../../Screens/AdminUserDeactivatedScreen/AdminUserDeactivatedScreen'
import AdminUserScreen from '../../Screens/AdminUserScreen/AdminUserScreen'
import AdminUserSoonExpireScreen from '../../Screens/AdminUserSoonExpireScreen/AdminUserSoonExpireScreen'
import AdminTrackRequestScreen from '../../Screens/AdminTrackRequestScreen/AdminTrackRequestScreen'
import Header from '../AdminLayouts/Header'
import MainLayout from '../AdminLayouts/MainLayout'

const Stack = createNativeStackNavigator()

export default function AdminMainStack() {
	return (
		<MainLayout>
			<Stack.Navigator
				initialRouteName={'AdminUserScreen'}
				screenOptions={{
					header: ({ navigation, route, options }) => (
						<Header navigation={navigation} title={getHeaderTitle(options, route.name)} />
					),
					headerStyle: { backgroundColor: colors.primary },
					headerBackTitleVisible: false,
					headerShadowVisible: false,
					headerTintColor: colors.primary,
					contentStyle: { backgroundColor: colors.primary }
				}}
			>
				<Stack.Screen options={{ title: 'Brugere' }} name="AdminUserScreen" component={AdminUserScreen} />
				<Stack.Screen
					options={{ title: 'Status på udløb' }}
					name="AdminUserSoonExpireScreen"
					component={AdminUserSoonExpireScreen}
				/>
				<Stack.Screen
					options={{ title: 'Inaktive brugere' }}
					name="AdminUserDeactivatedScreen"
					component={AdminUserDeactivatedScreen}
				/>
				<Stack.Screen
					options={{ title: 'Tildel spillelister' }}
					name="AssignPlaylistUserScreen"
					component={AssignPlaylistUserScreen}
				/>
				<Stack.Screen
					options={{ title: 'Internt miljø' }}
					name="AdminInternalPlaylistContainerScreen"
					component={AdminInternalPlaylistContainerScreen}
				/>
				<Stack.Screen
					options={{ title: 'Anmodede sange' }}
					name="AdminTrackRequestScreen"
					component={AdminTrackRequestScreen}
				/>
			</Stack.Navigator>
		</MainLayout>
	)
}
