import { useEffect, useState } from 'react'
import { Modal, StyleSheet, Text, View, useWindowDimensions } from 'react-native'
import { PrimaryButton, SecondaryButton } from '../../Components/Buttons/Button'
import { colors } from '../../Helpers/variables'

export const ConfirmationModal = ({
	modalVisible,
	description,
	handleSubmit,
	cancelCallback,
	titleCancel = 'Anuller',
	titleSubmit = 'Slet'
}) => {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	const getModalWidth = () => {
		if (isWideScreen) {
			if (width < 800) {
				return '70%'
			}

			return '40%'
		} else {
			return '80%'
		}
	}

	const trackStyles = StyleSheet.create({
		centeredView: {
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center'
		},
		modalView: {
			margin: 20,
			backgroundColor: colors.secondary,
			borderRadius: 20,
			padding: 35,
			shadowColor: '#000',
			shadowOffset: {
				width: 0,
				height: 2
			},
			shadowOpacity: 0.25,
			shadowRadius: 4,
			elevation: 5,
			width: isWideScreen ? '35%' : '70%',
			alignItems: 'center',
			justifyContent: 'center',
			height: isWideScreen ? '30%' : '40%',
		}
	})

	return (
		<Modal
			animationType="slide"
			transparent={true}
			visible={modalVisible}
			onRequestClose={() => {
				cancelCallback()
			}}
		>
			<View style={trackStyles.centeredView}>
				<View style={trackStyles.modalView}>
					<Text style={{ 
							marginBottom: isWideScreen ? 20 : 10, 
							color: 'white', 
							fontSize: isWideScreen ? 20 : (isSmallScreen ? 14 : 16), 
							textAlign: 'center'
						}}
					>{
						description}
					</Text>
					<View style={{ 
							width: '100%', 
							flexDirection: 'row'
						}}
					>
						<SecondaryButton 
							title={titleCancel} 
							onPress={() => cancelCallback()} 
							style={{ 
								width: '50%', 
								minHeight: isWideScreen ? 45 : 35, 
								flexDirection: 'row', 
								alignItems: 'center', 
								justifyContent: 'center', 
								marginRight: 5
							}} 
						/>
						<PrimaryButton 
							title={titleSubmit} 
							onPress={() => handleSubmit()} 
							style={{ width: '50%', minHeight: isWideScreen ? 45 : 35 }}
						/>
					</View>
				</View>
			</View>
		</Modal>
	)
}
