import { StyleSheet } from 'react-native'
import RNPickerSelect from 'react-native-picker-select'

export const Dropdown = ({ items, onValueChange, value, width = 45, marginLeft = 5, isDisabled = false, fontSize = 14, height = 40 }) => {
	const pickerSelectStyles = StyleSheet.create({
		inputIOS: {
			color: 'white',
			borderColor: 'white',
			backgroundColor: isDisabled ? '#D0D0D0' : '#334871',
			borderWidth: 2,
			borderRadius: 5,
			minWidth: 45,
			width: width,
			height: height,
			marginLeft: marginLeft,
			fontSize: fontSize
		},
		inputAndroid: {
			color: 'white',
			borderColor: 'white',
			backgroundColor: isDisabled ? '#D0D0D0' : '#334871',
			borderWidth: 2,
			borderRadius: 5,
			minWidth: 45,
			width: width + 5,
			height: height,
			marginLeft: marginLeft,
			fontSize: fontSize
		},
		inputWeb: {
			color: 'white',
			borderColor: 'white',
			backgroundColor: isDisabled ? '#D0D0D0' : '#334871',
			borderWidth: 2,
			borderRadius: 5,
			minWidth: 45,
			width: width,
			height: height,
			marginLeft: marginLeft,
			fontSize: fontSize
		}
	})

	return (
		<RNPickerSelect
			onValueChange={value => onValueChange(value)}
			items={items}
			value={value}
			style={pickerSelectStyles}
			placeholder={{}}
			disabled={isDisabled}
		/>
	)
}
