import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { NewItemLabelText } from '../Typography/Typography'
import { NEW_ITEM_LABEL_URL } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'

export default function NewItemLabel({ newItemLabelName, fontSize = 18, fontColor = 'yellow' }) {
    const [item, setItem] = useState(undefined)
    const api = useAxios()

    useEffect(() => {
        const boot = async () => {
            const { data } = await api.get(NEW_ITEM_LABEL_URL + newItemLabelName)
            if (data?.length > 0) {
                setItem(data[0])
            }
        }
        boot()
    }, [newItemLabelName])

    return item && (
        <View style={{ textAlign: 'center' }}>
            <NewItemLabelText fontSize={fontSize} fontColor={fontColor} style={{ fontWeight: 'bold' }}>{item?.label}</NewItemLabelText>
        </View>
    )
}
