import axios from 'axios'
import { useEffect, useState } from 'react'
import { FlatList, Modal, Pressable, ScrollView, StyleSheet, Text, View, useWindowDimensions } from 'react-native'
import { formatDate } from '../../Helpers/convertDate'
import { BULK_REQUESTED_SONGS, BULK_SONG_REQUEST, BULK_SONG_REQUEST_ALL, TAG_AS_DONE, colors } from '../../Helpers/variables'
import useTokens from '../../Hooks/useTokens'
import { PrimaryButton, SecondaryButton } from '../../Components/Buttons/Button'
import { RequestedSongsDetails } from '../../Components/Modals/RequestedSongsDetails'
import { NotificationModal } from '../../Components/Modals/NotificationModal'
import Checkbox from 'expo-checkbox'
import Loader from '../../Components/Loader/Loader'

export default function AdminTrackRequestScreen ({
	modalVisible,
	cancelCallback
}) {

	const spotifyLabel = 'Spotify'
	const youtubeLabel = 'Youtube'
	const csvLabel = 'CSV File'
	const appleLabel = 'Apple Music'

	const { getTokens } = useTokens()
	const { width, height } = useWindowDimensions()
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)
	const [loading, setLoading] = useState(false)
	const [requestedPlaylists, setRequestedPlaylists] = useState([])
	const [incompleteRequestedPlaylists, setIncompleteRequestedPlaylists] = useState([])
	const [showRequestedSongsDetailsModal, setShowRequestedSongsDetailsModal] = useState(false)
	const [songList, setSongList] = useState([])
	const [showNotifModal, setShowNotifModal] = useState(false)
	const [notifMessage, setNotifMessage] = useState(false)
	const [showEmailModal, setShowEmailModal] = useState(false)
	const [emailMessage, setEmailMessage] = useState(false)
	const [notifModal, setNotifModal] = useState({
		visible: false,
		title: '',
		description: '',
		isDisappearing: false
	})
	const [isSelectAllChecked, setSelectAllChecked] = useState(false)
	const [selectedItems, setSelectedItems] = useState([])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	useEffect(() => {
		handleResize()
	}, [width])

	useEffect(() => {
		getAllBulkRequests()
	}, [])

	const getModalWidth = () => {
		if (isWideScreen) {
			if (width < 800) {
				return '70%'
			}

			return '70%'
		} else {
			return '90%'
		}
	}

	const modalStyle = StyleSheet.create({
		centeredView: {
			flex: 1,
			alignItems: 'center'
		},
		modalView: {
			backgroundColor: colors.secondary,
			width: getModalWidth(),
			height: isWideScreen ? '90%' : '90%',
			marginTop: 20,
			alignItems: 'center'
		},
		innerLabel: {
			color: 'white',
			fontSize: isWideScreen ? 16 : (isSmallScreen ? 10 : 12),
		},
		errorMessage: {
			color: 'red',
			fontSize: isWideScreen ? 16 : 12,
			marginLeft: '10%',
		},
		trackListScrollView: {
			flexGrow: 1,
			width: '100%',
			minHeight: '30%',
			marginHorizontal: '3%',
			maxHeight: isWideScreen ? '90%' : '80%'
		}
	})

	const getAllBulkRequests = async (isClosingModal = false) => {
		setLoading(true)
		const { access } = await getTokens()
		const { data } = axios
			.get(BULK_SONG_REQUEST_ALL, {
				headers: { Authorization: `JWT ${access}`, 'Content-Type': 'multipart/form-data' }
			})
			.then(async response => {
				let playlists = response.data.map(item => item)
				playlists.sort((a, b) => {
					if (a.status !== b.status) {
						return a.status - b.status
					}

					return a.status_description.localeCompare(b.status_description, undefined, { numeric: true })
				})
			
				let pendingRequests = playlists.filter(item => item.status !== 1)
				setRequestedPlaylists(playlists)
				setIncompleteRequestedPlaylists(pendingRequests)
			})
			.catch(err => {
				alert({ error: true, message: 'Something went wrong' })
				console.log('createBulkRequest: ' + err)
				setLoading(false)
			})
			.finally(() => {
				setLoading(false)
				if (isClosingModal) {
					setShowRequestedSongsDetailsModal(false)
				}
			})

		return data
	}

	const getBulkRequestById = async (bulkId) => {
		setLoading(true)
		const { access } = await getTokens()
		const { data } = axios
			.get(BULK_REQUESTED_SONGS + 'all/' + bulkId, {
				headers: { Authorization: `JWT ${access}`, 'Content-Type': 'multipart/form-data' }
			})
			.then(async response => {
				setSongList(response.data)
				setShowRequestedSongsDetailsModal(true)
			})
			.catch(err => {
				alert({ error: true, message: 'Something went wrong' })
				console.log('getBulkRequestById: ' + err)
				setLoading(false)
			})
			.finally(() => {
				setLoading(false)
			})

		return data
	}

	const getSourcePlatform = (sourceId) => {
		let result = ''
		switch (sourceId) {
			case 0:
				result = spotifyLabel
				break
			case 1:
				result = youtubeLabel
				break
			case 2:
				result = appleLabel
				break
			case 3:
				result = csvLabel
				break
		}

		return result
	}

	const getFormattedDate = (date) => {
		if (!date) {
			return ''
		}
		return formatDate(date)
	}

	const handleViewDetails = (bulkId) => {
		getBulkRequestById(bulkId)
	}

	const cancelCallbackRequestedSongsDetails = () => {
		getAllBulkRequests(true)
	}

	const updateEmailSentFlag = async (item) => {
		const payload = {
			status: item.status,
			is_email_sent: true,
			playlist: item.playlist
		}

		const { access } = await getTokens()
		axios
			.put(BULK_SONG_REQUEST + item.id, payload, {
				headers: { Authorization: `JWT ${access}` }
			})
			.then(async response => {
				if (response.status === 200 || response.status === 201) {
					setEmailMessage('Email Sent')
					setShowEmailModal(true)
					await getAllBulkRequests()
		
				} else {
					setEmailMessage('Failed to tag Email Sent')
					setShowEmailModal(true)
					console.log('EMAIL FAILED', response)
				}
			})
			.catch(err => {
				setEmailMessage('Failed to tag Email Sent')
				setShowEmailModal(true)
				console.log(err.response.data)
			})
	}

	const tagAsDone = async (item) => {
		const payload = {
			status: 1,
		}

		const { access } = await getTokens()
		axios
			.put(TAG_AS_DONE + item.id, payload, {
				headers: { Authorization: `JWT ${access}` }
			})
			.then(async response => {
				console.log('response', response)
				if (response.status === 200 || response.status === 201) {
					setShowNotifModal(true)
					setNotifMessage('Song added successfully')
					await getAllBulkRequests()

				} else {
					setShowNotifModal(true)
					setNotifMessage('Tagging failed: ' + response.data)
					console.log('tagging error', response)
				}
			})
			.catch(err => {
				setShowNotifModal(true)
				setNotifMessage('Tagging failed: ' + err.response.data)
				console.log('tagging error', err.response.data)
			})
	}

	const closeNotifModal = () => {
		setNotifModal({
			visible: false,
			title: '',
			description: '',
			isDisappearing: false
		})
	}

	const deleteRequests = async () => {
		if (selectedItems.length === 0) {
			alert("Ingen elementer valgt til sletning")
			return
		}
	
		const { access } = await getTokens()
	
		axios
			.delete(BULK_SONG_REQUEST, { 
				headers: { Authorization: `JWT ${access}` },
				data: selectedItems
			})
			.then(async response => {
				if (response.status === 200 || response.status === 204) {
					alert("Valgte elementer blev slettet succesfuldt")
					setSelectedItems([])
					await getAllBulkRequests()
				} else {
					alert("Kunne ikke slette elementer")
				}
			})
			.catch(err => {
				console.log("Delete error:", err)
				alert("Fejl ved sletning af elementer.")
			})
	}
	
	const toggleSelectAll = () => {
		if (selectedItems.length === incompleteRequestedPlaylists.length) {
			setSelectedItems([])
			setSelectAllChecked(false)
		} else {
			let newSelection = []
			incompleteRequestedPlaylists.map(request => {
				newSelection.push(request.id)
			})
			setSelectedItems(newSelection)
			setSelectAllChecked(true)
		}
	}

	const toggleCheckbox = (id) => {
		let tempSelectedItems = []
		if (selectedItems.includes(id)) {
			selectedItems.map( item => {
				if (item !== id) {
					tempSelectedItems.push(item)
				}
			})
		} else {
			tempSelectedItems = [...selectedItems]
			tempSelectedItems.push(id)
		}
		setSelectedItems(tempSelectedItems)
		setSelectAllChecked(tempSelectedItems.length === incompleteRequestedPlaylists.length)
	}

	return (loading ? (
			<Loader />
		) : (
			<View style={modalStyle.centeredView}>
				<View style={{
						width: '100%',
						flexDirection: 'row',
						justifyContent: 'flex-end',
						alignItems: 'center',
					}}
				>
					<PrimaryButton
						title={"Slet valgte"}
						onPress={deleteRequests}
						style={{ 
							width: isWideScreen ? '10%' : (isSmallScreen ? '40%': '40%'), 
							minHeight: isWideScreen ? 35 : 30, 
							height: isWideScreen ? 35 : 30,
							backgroundColor: (selectedItems.length <= 0) ? 'gray' : colors.accent, 
						}}
						isDisabled={selectedItems.length <= 0}
					/>
				</View>
				<ScrollView style={modalStyle.trackListScrollView}>
					<View style={{
							flexDirection: 'row',
							justifyContent: 'flex-start',
							alignItems: 'center',
							marginRight: 0,
							width: '100%',
							borderColor: 'gray',
							borderBottomWidth: isWideScreen ? 2 : 1
						}}
					>
						<Pressable
							onPress={() => {
								toggleSelectAll()
							}}
						>
							<Checkbox
								status={Object.keys(selectedItems).length === incompleteRequestedPlaylists.length ? "checked" : "unchecked"}
								value={isSelectAllChecked}
								style={{marginRight: 5}}
								disabled={requestedPlaylists.length === 0}
								color={isSelectAllChecked ? colors.accent : undefined}
							/>
						</Pressable>
						<Text style={[modalStyle.innerLabel, { width: isWideScreen ? '15%' : (isSmallScreen ? '20%' : '20%') }]}>Username</Text>
						<Text style={[modalStyle.innerLabel, { width: isWideScreen ? '15%' : (isSmallScreen ? '20%' : '20%') }]}>{isSmallScreen ? 'Kilde platform' : 'Kilde platform'}</Text>
						<Text style={[modalStyle.innerLabel, { width: isWideScreen ? '20%' : (isSmallScreen ? '27%' : '27%') }]}>Spilleliste Navn</Text>
						<Text style={[modalStyle.innerLabel, { width: isWideScreen ? '15%' : (isSmallScreen ? '22%': '22%') }]}>{isWideScreen ? 'Dato Forespørgsel' : 'Dato Fores pørgsel'}</Text>
						<Text style={[modalStyle.innerLabel, { width: isWideScreen ? '15%' : (isSmallScreen ? '22%': '22%') }]}>Dato Opdateret</Text>
						<Text style={[modalStyle.innerLabel, { width: isWideScreen ? '10%' : (isSmallScreen ? '20%': '20%') }]}>Status</Text>
						<Text style={[modalStyle.innerLabel, { width: isWideScreen ? '25%' : (isSmallScreen ? '25%': '30%') }]}>{'            '}</Text>
					</View>
					<FlatList
						data={requestedPlaylists}
						renderItem={request =>
							<View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: '100%', marginRight: 0, }} >
								<Pressable
									disabled={request.item?.status === 1}
									onPress={() => {
										toggleCheckbox(request.item?.id)
									}}
								>
									<Checkbox
										value={selectedItems.includes(request.item?.id)}
										style={{marginRight: 5}}
										disabled={request.item?.status === 1}
										color={selectedItems.includes(request.item?.id) ? colors.accent : undefined}
									/>
								</Pressable>
								<Text style={[modalStyle.innerLabel, { width: isWideScreen ? '15%' : (isSmallScreen ? '20%' : '20%') }]}>{request.item?.username}</Text>
								<Text style={[modalStyle.innerLabel, { width: isWideScreen ? '15%' : (isSmallScreen ? '20%' : '20%') }]}>{getSourcePlatform(request.item?.source_external_app)}</Text>
								<Text style={[modalStyle.innerLabel, { width: isWideScreen ? '20%' : (isSmallScreen ? '27%' : '27%') }]}>{request.item?.playlist_name}</Text>
								<Text style={[modalStyle.innerLabel, { width: isWideScreen ? '15%' : (isSmallScreen ? '22%': '22%') }]}>{getFormattedDate(request.item?.creation_date)}</Text>
								<Text style={[modalStyle.innerLabel, { width: isWideScreen ? '15%' : (isSmallScreen ? '22%': '22%') }]}>{getFormattedDate(request.item?.date_updated)}</Text>
								<Text style={[modalStyle.innerLabel, { width: isWideScreen ? '10%' : (isSmallScreen ? '20%': '20%') }]}>{request.item?.status_description}</Text>
								<PrimaryButton
									title='Vis'
									onPress={() => handleViewDetails(request.item?.id)}
									style={{ width: isWideScreen ? '10%' : (isSmallScreen ? '15%': '15%'), minHeight: isWideScreen ? 35 : 30, height: isWideScreen ? 35 : 30 }}
								/>
								<PrimaryButton
									title='Email'
									onPress={() => updateEmailSentFlag(request.item)}
									style={{ width: isWideScreen ? '10%' : (isSmallScreen ? '15%': '15%'), minHeight: isWideScreen ? 35 : 30, height: isWideScreen ? 35 : 30 }}
									isDisabled = {request.item?.is_email_sent}
								/>
							</View>
						}
						ListEmptyComponent={
							<Text style={{ color: 'white' }}>Ingen data...</Text>
						}
						keyExtractor={request => request.id}
					/>
				</ScrollView>

				{showRequestedSongsDetailsModal && (
					<RequestedSongsDetails
						songList={songList}
						cancelCallback={cancelCallbackRequestedSongsDetails}
						isAdmin={true}
						tagAsDone={tagAsDone}
						showNotifModal={showNotifModal}
						setShowNotifModal={setShowNotifModal}
						notifMessage={notifMessage}
					/>
				)}
				
				{showEmailModal && (
					<NotificationModal
						title={'Done'}
						description={emailMessage}
						visible={notifModal}
						closeNotifModal={closeNotifModal}
						setModalVisibleExternal={setShowEmailModal}
						isDisappearing={false}
					/>
				)}
			</View>
		)
	)
}
