import React, { useEffect, useState } from 'react'
import { FlatList, StyleSheet, Text, View, useWindowDimensions } from 'react-native'
import { colors } from '../../Helpers/variables'
import { PrimaryButton } from '../Buttons/Button'
import { ScrollView } from 'react-native'

export const Listbox = ({ items, hasRemoveAll = false, title, handleRemoveAll, emptyLabel = 'Ingen spillelister' }) => {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	useEffect(() => {
		handleResize()
	}, [width, height])

	useEffect(() => {
		handleResize()
	}, [width, height])

	const styles = StyleSheet.create({
		main: {
			width: '50%', 
			height: '80%',
			minHeight: '80%'
		},
		container: {
			flex: 1,
			marginLeft: isWideScreen ? 10 : 5,
			marginRight: isWideScreen ? 15 : 10,
			color: 'white',
			backgroundColor: colors.primary,
			minHeight: 300
		},
		title: {
			color: 'white',
			marginLeft: isWideScreen ? 20 : 10,
			fontSize: 16
		},
		listboxText: {
			color: 'white',
			margin: isWideScreen ? 10 : 5,
			fontSize: isWideScreen ? 15: 12
		}
	})
	
	return (
		<View style={styles.main}>
			<View>
				<Text style={styles.title}>{title}</Text>
			</View>
			{hasRemoveAll && (
				<View>
					<PrimaryButton 
						title={'Fjern alt'} 
						onPress={() => handleRemoveAll()} 
						style={{ 
							width: isWideScreen ? 180 : (isSmallScreen ? 90 : 120), 
							minHeight: isWideScreen ? 30 : 35,
							marginLeft: 10,
						}}  
					/>
				</View>
			)}
			<ScrollView style={styles.container}>
				<FlatList
					data={items}
					ListEmptyComponent={
						<View>
							<Text style={{ 
									color: colors.accent, 
									margin: isWideScreen ? 10 : 5, 
									fontSize: isWideScreen ? 20 : (isSmallScreen ? 16 : 18) 
								}}
							>{emptyLabel}...
							</Text>
						</View>
					}
					renderItem={({ item }) => (
						<View>
							<Text style={styles.listboxText}>
								{item.username ? item.username : item.name ? item.name : item.playlist_name}
							</Text>
						</View>
					)}
					showsVerticalScrollIndicator={false}
					showsHorizontalScrollIndicator={false}
					keyExtractor={item => item.id}
				/>
			</ScrollView>
		</View>
	)
}
