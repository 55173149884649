import React, { useEffect, useState } from 'react'
import useSound from '../../Hooks/useSound'
import TrackPlayer from 'react-native-track-player'

export const PlaybackStatusContext = React.createContext()

export const PlaybackStatusProvider = ({ children }) => {
	const [playbackStatus, setPlaybackStatus] = useState({
		progressUpdateIntervalMillis: 500,
		positionMillis: 0,
		shouldPlay: false,
		rate: 1.0,
		shouldCorrectPitch: false,
		volume: 1.0,
		isMuted: false,
		isLooping: false,
		isLoaded: false,
		isPlaying: false
	})

	// useEffect(() => {
	// 	const boot = async () => {
	// 		if (currentPlayingTrack) {
	// 			let isPlaying = (await TrackPlayer.getPlaybackState()).state === 'playing' ? true : false
	// 			if (isPlaying !== playbackStatus.isPlaying) {
	// 				setPlaybackStatus({
	// 					isPlaying: isPlaying
	// 				})
	// 			}
	// 		}
	// 	}
	// 	boot()
	// }, [currentPlayingTrack])

	return (
		<PlaybackStatusContext.Provider
			value={{
				playbackStatus,
				setPlaybackStatus
			}}
		>
			{children}
		</PlaybackStatusContext.Provider>
	)
}
