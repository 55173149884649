import { Ionicons } from '@expo/vector-icons'
import React, { useState, useEffect } from 'react'
import { Pressable, View, useWindowDimensions } from 'react-native'
import { colors } from '../../../../../Helpers/variables'
import useSound from '../../../../../Hooks/useSound'
import QueueModal from '../../../../Modals/QueueModal'

const QueueButton = () => {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	const {
		currentPlayingTrack,
		trackList,
		currentPlayingTrackIndex,
		setTrackList,
		setCurrentPlayingTrackIndex,
		addTracksToPlayer
	} = useSound()
	const [queueModalOpen, setQueueModalOpen] = useState(false)

	const handleClickQueue = () => {
		if (currentPlayingTrack) {
			setQueueModalOpen(true)
		}
	}

	return (
		<View style={{ marginLeft: isWideScreen ? 10 : 0 }}>
			<Pressable disabled={currentPlayingTrack === null}>
				<Ionicons
					name="list-outline"
					size={20}
					style={{marginLeft: 3}}
					color={currentPlayingTrack ? 'white' : colors.secondary}
					onPress={() => handleClickQueue()}
				/>
			</Pressable>
			{queueModalOpen && (
				<QueueModal
					setModalOpen={setQueueModalOpen}
					isModalOpen={queueModalOpen}
					trackList={trackList}
					currentPlayingTrackIndex={currentPlayingTrackIndex}
					setTrackList={setTrackList}
					setCurrentPlayingTrackIndex={setCurrentPlayingTrackIndex}
					addTracksToPlayer={addTracksToPlayer}
				/>
			)}
		</View>
	)
}

export default React.memo(QueueButton)
