import React, { useEffect, useState } from 'react'
import { Image, ImageBackground, Text, View, useWindowDimensions, TouchableHighlight, Pressable } from 'react-native'
import { MEDIAPLAYER_ACQUISITION_URL, colors } from '../../../../Helpers/variables'
import useAxios from '../../../../Hooks/useAxios'

import truncateStr from '../../../../Helpers/truncateStr'
import useAuth from '../../../../Hooks/useAuth'
import useSound from '../../../../Hooks/useSound'
import PlayButton from './TrackControls/PlayButton'
import QueueButton from './TrackControls/QueueButton'
import RepeatButton from './TrackControls/RepeatButton'
import SeekControls from './TrackControls/SeekControls'
import ShuffleButton from './TrackControls/ShuffleButton'
import TimerControls from './TrackControls/TimerControls'
import TrackChangeButton from './TrackControls/TrackChangeButton'
import VolumeControls from './TrackControls/VolumeControls'
import { useNavigation } from '@react-navigation/native'
import { Ionicons } from '@expo/vector-icons'
import { LyricsModal } from '../../../Modals/LyricsModal'

const fallback = require('../../../../assets/fallback_cat.png')
const logo = require('../../../../assets/icon.png')
export default function TrackControls({ CopilotStep, WalkthroughableView }) {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	const navigation = useNavigation()

	const { currentPlayingTrack, mediaPlayerAcquisition, setMediaPlayerAcquisition } = useSound()
	const [loading, setLoading] = useState(true)
	const api = useAxios()
	const { isAdmin } = useAuth()

	const [lyricsVisible, setLyricsVisible] = useState(false)

	const getMediaPlayerAcquisition = async () => {
		const { data } = await api.get(MEDIAPLAYER_ACQUISITION_URL)
		if (data.length > 0) {
			setMediaPlayerAcquisition({
				isShuffleBtnVisible: true,
				isRepeatBtnVisible: true
			})
		} else
			setMediaPlayerAcquisition({
				isShuffleBtnVisible: true,
				isRepeatBtnVisible: true
			})
	}

	useEffect(() => {
		getMediaPlayerAcquisition()
		setLoading(false)
	}, [])

	const [image, setImage] = useState(undefined)

	useEffect(() => {
		if (currentPlayingTrack) {
			setImage(currentPlayingTrack.album_photo)
		}
	}, [currentPlayingTrack])

	return (
		<View style={{ 
				paddingHorizontal: isWideScreen ? 15 : 10, 
				marginBottom: isWideScreen ? 10 : 0, 
				marginTop: currentPlayingTrack ? '5%' : '75%', 
				width: '100%'
			}}
		>
			{currentPlayingTrack && (
				<View style={{ alignItems: 'center' }}>
					<ImageBackground
						blurRadius={20}
						imageStyle={{ borderRadius: 15 }}
						source={image ? { uri: image } : fallback}
						style={{ width: isWideScreen ? 230 : '100%', alignItems: 'center'}}
						resizeMode={'cover'}
					>
						<Image
							source={image ? { uri: image } : logo}
							style={{
								height: isSmallScreen ? 100 : (isWideScreen ? 150 : 125),
								width: isSmallScreen ? 100 : (isWideScreen ? 150 : 125),
								borderRadius: 100,
								marginTop: '15%',
								borderColor: colors.accent,
								borderWidth: 2
							}}
							resizeMode={'cover'}
						/>
						<View style={{ paddingVertical: isWideScreen ? 15 : 10 }}>
							<Text style={{ color: 'white', textAlign: 'center', fontSize: 14 }}>
								{truncateStr(currentPlayingTrack.song_title, 20)}
							</Text>
							<TouchableHighlight
								onPress={() => {
									if (navigation) {
										navigation.navigate('ArtistTracks', {
											artistId: currentPlayingTrack.artist,
											photo: currentPlayingTrack.album_photo,
											title: currentPlayingTrack.artist_name
										})
									}
								}}
							>
								<Text style={{ color: 'white', textAlign: 'center', fontSize: 10 }}>
									{truncateStr(currentPlayingTrack.artist_name, 20)}
								</Text>
							</TouchableHighlight>
						</View>
					</ImageBackground>
				</View>
			)}
			<SeekControls />
			<View style={{ 
					flexDirection: 'row', 
					justifyContent: 'space-between', 
					alignItems: 'center',
					width: '100%'
				}}
			>
				{mediaPlayerAcquisition.isShuffleBtnVisible && (
					<CopilotStep order={5} name={'asjdfhnvuwfive'} text={'Blander numrene tilfældigt'}>
						<WalkthroughableView>
							<ShuffleButton />
						</WalkthroughableView>
					</CopilotStep>
				)}
				<CopilotStep
					order={mediaPlayerAcquisition.isShuffleBtnVisible ? 6 : 5}
					name={'asjdfhnvuwsix'}
					text={'Afspil forrige sang'}
				>
					<WalkthroughableView>
						<TrackChangeButton size={isWideScreen ? 30 : (isSmallScreen ? 18 : 22)} type="prev" />
					</WalkthroughableView>
				</CopilotStep>
				<CopilotStep
					order={mediaPlayerAcquisition.isShuffleBtnVisible ? 7 : 6}
					name={'asjdfhnvuwseven'}
					text={'Afspil sang'}
				>
					<WalkthroughableView>
						<PlayButton size={isWideScreen ? 55 : (isSmallScreen ? 20 : 30)} track={currentPlayingTrack}/>
					</WalkthroughableView>
				</CopilotStep>
				<CopilotStep
					order={mediaPlayerAcquisition.isShuffleBtnVisible ? 8 : 7}
					name={'asjdfhnvuweight'}
					text={'Afspil næste sang'}
				>
					<WalkthroughableView>
						<TrackChangeButton size={isWideScreen ? 30 : (isSmallScreen ? 18 : 22)} type="next" />
					</WalkthroughableView>
				</CopilotStep>
				{mediaPlayerAcquisition.isRepeatBtnVisible && (
					<CopilotStep
						order={mediaPlayerAcquisition.isShuffleBtnVisible ? 9 : 8}
						name={'asjdfhnvuwnine'}
						text={'Gentag alle sange og gentag én sang'}
					>
						<WalkthroughableView>
							<RepeatButton />
						</WalkthroughableView>
					</CopilotStep>
				)}
			</View>
			<CopilotStep
				order={mediaPlayerAcquisition.isShuffleBtnVisible ? 10 : 9}
				name={'asjdfhnvuwten'}
				text={'Skift sangens lydstyrke'}
			>
				<WalkthroughableView>
					<VolumeControls />
				</WalkthroughableView>
			</CopilotStep>
			{isWideScreen ? (
				<>
					<View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 10, width: '100%' }}>
						<CopilotStep
							order={mediaPlayerAcquisition.isShuffleBtnVisible ? 11 : 10}
							name={'asjdfhnvuw11'}
							text={'Tænd/sluk timeren'}
						>
							<WalkthroughableView>
								<TimerControls />
							</WalkthroughableView>
						</CopilotStep>
						<Pressable>
							<Ionicons
								name="newspaper-outline"
								size={24}
								color={currentPlayingTrack ? 'white' : colors.secondary}
								style={{ marginLeft: 20 }}
								onPress={() => {
									if (currentPlayingTrack) {
										setLyricsVisible(true)
									}
								}}
							/>
						</Pressable>
						<CopilotStep order={mediaPlayerAcquisition.isShuffleBtnVisible ? 12 : 11} name={'asjdfhnvuw12d'} text={'Kø'}>
							<WalkthroughableView>
								<QueueButton />
							</WalkthroughableView>
						</CopilotStep>
					</View>
				</>
			) : (
				<>
					<View style={{ 
							flexDirection: 'column', 
							justifyContent: 'center', 
							marginTop: 5,
							width: '100%' }}>
						<CopilotStep
							order={mediaPlayerAcquisition.isShuffleBtnVisible ? 11 : 10}
							name={'asjdfhnvuw11'}
							text={'Tænd/sluk timeren'}
						>
							<WalkthroughableView>
								<TimerControls />
							</WalkthroughableView>
						</CopilotStep>

						<View style={{flexDirection: 'row'}}>
							<Pressable>
								<Ionicons
									name="newspaper-outline"
									size={18}
									color={currentPlayingTrack ? 'white' : colors.secondary}
									style={{ marginHorizontal: 5 }}
									onPress={() => {
										if (currentPlayingTrack) {
											setLyricsVisible(true)
										}
									}}
								/>
							</Pressable>
							<CopilotStep 
								order={mediaPlayerAcquisition.isShuffleBtnVisible ? 12 : 11} 
								name={'asjdfhnvuw12d'} 
								text={'Kø'}
							>
								<WalkthroughableView>
									<QueueButton />
								</WalkthroughableView>
							</CopilotStep>
						</View>
					</View>
				</>
			)}
			{lyricsVisible && (
				<LyricsModal
					setModalVisible={setLyricsVisible}
					modalVisible={lyricsVisible}
					closeLyricsModal={setLyricsVisible}
				/>
			)}
		</View>
	)
}
