import React, { useEffect, useState } from 'react'

import { Ionicons, MaterialIcons } from '@expo/vector-icons'
import axios from 'axios'
import {
	Image,
	ImageBackground,
	Pressable,
	StyleSheet,
	Text,
	TouchableHighlight,
	View,
	useWindowDimensions
} from 'react-native'
import { Filter } from '../../../Components/Forms/Filter'
import { NotificationModal } from '../../../Components/Modals/NotificationModal'
import { TherapistModal } from '../../../Components/Modals/TherapistModal'
import { COPY_PLAYLIST_URL, MUSICTHERAPISTS_URL, colors } from '../../../Helpers/variables'
import useLayout from '../../../Hooks/useLayout'
import usePlaybackStatus from '../../../Hooks/usePlaybackStatus'
import useSound from '../../../Hooks/useSound'
import useTokens from '../../../Hooks/useTokens'

export default function PlaylistDetails({
	tracksList,
	hasCopyPlaylistBtn = false,
	edit,
	isTrackPositionChangeable,
	setEdit,
	isRepeatAllTracks = false,
	musicTherapistName,
	musicTherapistId,
	isHideTherapist = false,
	artistName = '',
	saveTracksPosition,
	copyMyPlaylistLabel = 'Lavet af Musicmind',
	sortByValues,
	onSortValueChange,
	sortValue,
	duration,
	...props
}) {
	const { width, height } = useWindowDimensions()
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 450)
	const { photo, title, tracks, description } = props
	const [playlistId, setPlaylistId] = useState(tracksList[0]?.playlist)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 450)
	}

	const detailsStyle = StyleSheet.create({
		title: {
			color: 'white',
			fontSize: 20,
			fontWeight: 'bold'
		},
		header: {
			color: colors.accent,
			fontSize: 16,
			fontStyle: 'italic',
			width: 100
		},
		data: {
			color: 'white',
			fontSize: 16
		}
	})

	const { deviceType } = useLayout()

	const fallback = require('../../../assets/fallback_cat.png')
	const logo = require('../../../assets/icon.png')
	const { getTokens } = useTokens()
	const [therapistModal, setTherapistModal] = useState(false)
	const [therapistInfo, setTherapistInfo] = useState(undefined)
	const {
		handleClickShuffle,
		isShuffleActive,
		handleClickRepeat,
		repeatType,
		mediaPlayerAcquisition,
		currentPlayingTrack,
		addTracks,
		playTrack
	} = useSound()
	const { setPlaybackStatus } = usePlaybackStatus()
	const [totalDuration, setTotalDuration] = useState('')
	const [loading, setLoading] = useState(false)

	const [notifModal, setNotifModal] = useState({
		visible: false,
		title: '',
		description: '',
		isDisappearing: false
	})

	const { isSidebarOpen } = useLayout()

	const closeNotifModal = () => {
		setNotifModal({
			visible: false,
			title: '',
			description: '',
			isDisappearing: false
		})
	}

	const openNotifModal = (title, description, isDisappearing = false) => {
		setNotifModal({
			visible: true,
			title: title,
			description: description,
			isDisappearing: isDisappearing
		})
	}

	const handleCopyPlaylist = async () => {
		setLoading(true)
		await createCopiedPlaylist()
	}

	const createCopiedPlaylist = async () => {
		const { access } = await getTokens()

		const formData = new FormData()
		formData.append('playlist_id', playlistId)

		axios
			.post(COPY_PLAYLIST_URL, formData, {
				headers: { Authorization: `JWT ${access}`, 'Content-Type': 'multipart/form-data' }
			})
			.then(response => {
				console.log('response', response)
				if (response.status === 201) {
					console.log('COPY CREATED', response)
					openNotifModal('Opret spilleliste', 'Spilleliste kopieret med succes')
				} else {
					console.log('FAILED TO CREATE COPY')
					openNotifModal('Opret spilleliste', 'Kunne ikke tilføje')
				}
				setLoading(false)
			})
			.catch(err => {
				console.log(err)
				setLoading(false)
			})
	}

	const getMusicTherapistInfo = async () => {
		if (!musicTherapistId) return

		const { access } = await getTokens()
		axios
			.get(MUSICTHERAPISTS_URL + musicTherapistId, {
				headers: { Authorization: `JWT ${access}` }
			})
			.then(({ data }) => {
				if (data.length > 0) {
					setTherapistInfo(data[0])
					setTherapistModal(true)
				}
			})
			.catch(error => {
				console.log(error)
			})
	}

	useEffect(() => {
		let duration = 0

		if (tracksList?.length > 0) {
			tracksList?.map(item => {
				duration += item?.track?.duration ?? 0
			})

			let durationSum = formatMillisecondsToTime(duration)
			setTotalDuration(durationSum)
		}
	}, [tracksList])

	const formatMillisecondsToTime = milliseconds => {
		const totalSeconds = Math.round(milliseconds / 1000)
		const hours = Math.floor(totalSeconds / 3600)
		const minutes = Math.floor((totalSeconds % 3600) / 60)
		const seconds = totalSeconds % 60

		const paddedHours = hours > 0 ? String(hours).padStart(2, '0') : '00'
		const paddedMinutes = String(minutes).padStart(2, '0')
		const paddedSeconds = String(seconds).padStart(2, '0')

		return hours > 0 ? `${paddedHours}:${paddedMinutes}:${paddedSeconds}` : `${paddedMinutes}:${paddedSeconds}`
	}

	const handlePressPlay = async () => {
		await addTracks(tracksList)
		await playTrack(tracksList[0]?.track, 0)
		setPlaybackStatus({
			isPlaying: true
		})
	}

	return (
		<>
			<View
				style={{
					flexDirection: deviceType !== 1 || isSidebarOpen ? 'row' : 'column',
					alignItems: deviceType !== 1 ? 'center' : 'flex-start',
					marginBottom: 10,
					width: '100%'
				}}
			>
				<ImageBackground
					blurRadius={20}
					imageStyle={{ borderRadius: 15 }}
					source={photo ? { uri: photo } : fallback}
					style={{ height: 150, width: 150 }}
					resizeMode={'cover'}
				>
					<Image
						source={photo ? { uri: photo } : logo}
						style={{
							height: 100,
							width: 100,
							borderRadius: 50,
							marginLeft: 25,
							marginTop: 25,
							borderColor: colors.accent,
							borderWidth: 2
						}}
						resizeMode={'cover'}
					/>
				</ImageBackground>
				<View style={{ flex: 1, marginLeft: 10, marginTop: deviceType !== 1 ? 0 : 20 }}>
					<View style={{ marginBottom: 10, flexDirection: 'row' }}>
						<Text style={detailsStyle.header}>Titel:</Text>
						<Text style={detailsStyle.title}>{title}</Text>
					</View>
					{isHideTherapist === false ? (
						musicTherapistName ? (
							<View>
								<TouchableHighlight
									onPress={() => {
										getMusicTherapistInfo()
									}}
								>
									<View style={{ flexDirection: 'row' }}>
										<Text style={detailsStyle.header}>Lavet af:</Text>
										<Text style={detailsStyle.data}>{musicTherapistName}</Text>
									</View>
								</TouchableHighlight>
							</View>
						) : (
							<View>
								<View style={{ flexDirection: 'row' }}>
									<Text style={detailsStyle.header}>Lavet af:</Text>
									<Text style={detailsStyle.data}>{copyMyPlaylistLabel}</Text>
								</View>
							</View>
						)
					) : (
						<View>
							<View style={{ flexDirection: 'row' }}>
								<Text style={detailsStyle.header}>Lavet af:</Text>
								<Text style={detailsStyle.data}>{artistName}</Text>
							</View>
						</View>
					)}
					{description && (
						<View style={{ flexDirection: 'row' }}>
							<Text style={detailsStyle.header}>Beskrivelse:</Text>
							<Text style={[detailsStyle.data, { textAlign: 'left', width: '90%' }]}>{description}</Text>
						</View>
					)}
					{/* {tracks && (
						<View style={{ flexDirection: 'row' }}>
							<View>
								<Text style={detailsStyle.header}>Antal numre:</Text>
							</View>
							<View style={{ width: '90%' }}>
								<Text style={detailsStyle.data}>{tracks}</Text>
							</View>
						</View>
					)} */}
					{/* {tracks && (
						<View style={{ flexDirection: 'row' }}>
							<View>
								<Text style={detailsStyle.header}>Samlet varighed:</Text>
							</View>
							<View style={{ width: '90%' }}>
								<Text style={detailsStyle.data}>{totalDuration}</Text>
							</View>
						</View>
					)} */}
				</View>
			</View>
			<View
				style={{
					width: '100%',
					flexDirection: 'row',
					marginVertical: 10,
					paddingHorizontal: 5,
					alignItems: 'center',
					justifyContent: 'space-between'
				}}
			>
				<View
					style={{
						width: '80%',
						flexDirection: !isSmallScreen || (isSmallScreen && !isSidebarOpen) ? 'row' : 'column',
						alignItems: !isSmallScreen || (isSmallScreen && !isSidebarOpen) ? 'center' : 'flex-start'
					}}
				>
					<Pressable
						disabled={tracksList && tracksList?.length == 0}
						onPress={async () => {
							handlePressPlay()
						}}
					>
						<View
							style={{
								backgroundColor: colors.accent,
								borderRadius: 40,
								paddingVertical: 7,
								paddingHorizontal: 10,
								flexDirection: 'row',
								alignItems: 'center'
							}}
						>
							<Ionicons
								name="play-circle"
								size={isSidebarOpen ? 18 : 13}
								style={{ marginRight: 5 }}
								color={colors.background}
							/>
							<Text style={{ fontSize: 14, fontWeight: 'bold', color: colors.background }}>Afspil</Text>
						</View>
					</Pressable>

					{hasCopyPlaylistBtn && tracksList?.length > 0 && (
						<Pressable onPress={() => handleCopyPlaylist()} disabled={loading || notifModal.visible}>
							<View
								style={{
									backgroundColor: loading || notifModal.visible ? 'gray' : colors.accent,
									borderRadius: 40,
									paddingVertical: 7,
									paddingHorizontal: 10,
									flexDirection: 'row',
									alignItems: 'center',
									marginLeft: 10
								}}
							>
								<Ionicons name="copy" size={16} style={{ marginRight: 5 }} color={colors.background} />
								<Text style={{ fontSize: 14, fontWeight: 'bold', color: colors.background }}>Kopier</Text>
							</View>
						</Pressable>
					)}

					{isTrackPositionChangeable &&
						(!edit ? (
							<Pressable onPress={() => setEdit(true)}>
								<View
									style={{
										backgroundColor: colors.accent,
										borderRadius: 40,
										paddingVertical: isSidebarOpen ? 7 : 10,
										paddingHorizontal: 5,
										marginHorizontal: !isSmallScreen || (isSmallScreen && !isSidebarOpen) ? 10 : 0,
										flexDirection: 'row',
										marginVertical: isSmallScreen && isSidebarOpen ? 5 : 0
										// alignItems: 'center'
									}}
								>
									<Ionicons
										name="pencil-sharp"
										size={isSidebarOpen ? 14 : 13}
										style={{ marginRight: 3 }}
										color={colors.background}
									/>
									<Text style={{ fontSize: 14, fontWeight: 'bold', color: colors.background }}>
										Rediger Spilleliste
									</Text>
								</View>
							</Pressable>
						) : (
							<Pressable
								onPress={async () => {
									await saveTracksPosition(tracksList)
									setEdit(false)
								}}
							>
								<View
									style={{
										backgroundColor: colors.accent,
										borderRadius: 40,
										paddingVertical: 7,
										paddingHorizontal: 20,
										marginHorizontal: isSmallScreen && isSidebarOpen ? 0 : 10,
										flexDirection: 'row',
										alignItems: 'center',
										marginVertical: isSmallScreen && isSidebarOpen ? 5 : 0
									}}
								>
									<Ionicons
										name="pencil-sharp"
										size={isSidebarOpen ? 14 : 13}
										style={{ marginRight: 3 }}
										color={colors.background}
									/>
									<Text style={{ fontSize: 14, fontWeight: 'bold', color: colors.background }}>Gem</Text>
								</View>
							</Pressable>
						))}
					{!isSmallScreen && mediaPlayerAcquisition.isShuffleBtnVisible && (
						<Pressable disabled={currentPlayingTrack === null}>
							<Ionicons
								name="shuffle"
								size={26}
								color={currentPlayingTrack === null ? colors.secondary : isShuffleActive ? colors.accent : 'white'}
								style={{ marginHorizontal: 20 }}
								onPress={() => handleClickShuffle()}
							/>
						</Pressable>
					)}
					{!isSmallScreen &&
						mediaPlayerAcquisition.isRepeatBtnVisible &&
						(repeatType === 'one' ? (
							<Pressable>
								<MaterialIcons
									name="repeat-one"
									size={26}
									color={currentPlayingTrack === null ? colors.secondary : colors.accent}
									style={{ marginHorizontal: 5 }}
									onPress={() => handleClickRepeat(isRepeatAllTracks ? 'repeatAllTracks' : 'none')}
								/>
							</Pressable>
						) : (
							<Pressable>
								<Ionicons
									name="ios-repeat-sharp"
									size={26}
									color={
										currentPlayingTrack === null ? colors.secondary : repeatType === 'all' ? colors.accent : 'white'
									}
									style={{ marginHorizontal: 5 }}
									onPress={() => handleClickRepeat(repeatType === 'none' ? 'all' : 'one')}
								/>
							</Pressable>
						))}
				</View>
				{(!isSmallScreen || (isSmallScreen && !isSidebarOpen)) && tracksList?.length > 0 && sortByValues && (
					<View style={{ width: '20%', flexDirection: 'row', justifyContent: 'flex-end' }}>
						<Filter
							value={sortValue}
							items={sortByValues}
							onSortValueChange={onSortValueChange}
							defaultButtonText={sortValue}
							// newItemLabelName={'Filter Feature'}
						/>
					</View>
				)}
			</View>
			{isSmallScreen && (
				<View style={{ width: '100%', flexDirection: 'row', alignItems: 'center' }}>
					{mediaPlayerAcquisition.isShuffleBtnVisible && (
						<Pressable disabled={currentPlayingTrack === null}>
							<Ionicons
								name="shuffle"
								size={26}
								color={currentPlayingTrack === null ? colors.secondary : isShuffleActive ? colors.accent : 'white'}
								style={{ marginHorizontal: 5 }}
								onPress={() => handleClickShuffle()}
							/>
						</Pressable>
					)}
					{mediaPlayerAcquisition.isRepeatBtnVisible &&
						(repeatType === 'one' ? (
							<Pressable>
								<MaterialIcons
									name="repeat-one"
									size={26}
									color={currentPlayingTrack === null ? colors.secondary : colors.accent}
									style={{ marginHorizontal: 5 }}
									onPress={() => handleClickRepeat(isRepeatAllTracks ? 'repeatAllTracks' : 'none')}
								/>
							</Pressable>
						) : (
							<Pressable>
								<Ionicons
									name="ios-repeat-sharp"
									size={26}
									color={
										currentPlayingTrack === null ? colors.secondary : repeatType === 'all' ? colors.accent : 'white'
									}
									style={{ marginHorizontal: 5 }}
									onPress={() => handleClickRepeat(repeatType === 'none' ? 'all' : 'one')}
								/>
							</Pressable>
						))}
					{isSmallScreen && isSidebarOpen && tracksList?.length > 0 && sortByValues && (
						<View style={{ marginLeft: '50%', flexDirection: 'row', justifyContent: 'flex-end' }}>
							<Filter
								value={sortValue}
								items={sortByValues}
								onSortValueChange={onSortValueChange}
								defaultButtonText={sortValue}
								// newItemLabelName={'Filter Feature'}
							/>
						</View>
					)}
				</View>
			)}
			{notifModal.visible && (
				<NotificationModal
					title={notifModal.title}
					description={notifModal.description}
					visible={notifModal.visible}
					closeNotifModal={closeNotifModal}
					isDisappearing={notifModal.isDisappearing}
				/>
			)}
			{therapistModal && (
				<TherapistModal modalVisible={therapistModal} setModalVisible={setTherapistModal} info={therapistInfo} />
			)}
		</>
	)
}
