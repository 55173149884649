import React, { useEffect, useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import { Pressable, ImageBackground, View, Text, useWindowDimensions } from 'react-native'
import { colors } from '../../../Helpers/variables'
import { InfoButton } from '../../../Components/Buttons/Button';
import { Popup } from '../../../Components/Playlists/Popup';

export default function ContainerCard({ item }) {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	useEffect(() => {
		handleResize()
	}, [width])

	const { navigate } = useNavigation()

	const [image, setImage] = React.useState(null)
	const fallback = require('../../../assets/fallback_cat.png')
	const [modalVisible, setModalVisible] = useState(false)

	React.useEffect(() => {
		if (item.item.Photo) {
			setImage(item.item.Photo)
		}
	}, [])

	return (
		<Pressable onPress={() => navigate('InternalPlaylistScreen', { categoryId: item.item.id, categoryName: item.item.name })}>
			<View
				style={{
					width: isSmallScreen ? 100 : (isWideScreen ? 180 : 120),
					borderRadius: 10,
					backgroundColor: colors.secondary,
					marginBottom: 15,
					marginHorizontal: 15,
				}}
			>
				<ImageBackground
					source={image ? { uri: image } : fallback}
					resizeMode="cover"
					imageStyle={{ borderRadius: 10 }}
					style={{
						width: isSmallScreen ? 100 : (isWideScreen ? 180 : 120),
						height: isSmallScreen ? 100 : (isWideScreen ? 180 : 120)
					}}
				>
					{item.item.description && <InfoButton size={24} fontSize={isWideScreen ? 16 : 14} setModalVisible={setModalVisible} />}
				</ImageBackground>

				<View style={{ paddingHorizontal: 15, marginVertical: 20 }}>
					<Text style={{ color: 'white', textAlign: 'center' }}>{item.item.name}</Text>
				</View>
			</View>
			{
				modalVisible && (
					<Popup modalVisible={modalVisible} setModalVisible={setModalVisible} description={item.item.description} />
				)
			}
		</Pressable>
	)
}
