export function playlistSortByName(arrayList, isNameOnly = true) {
	if (arrayList) {
		if (arrayList.length > 0) {
			return arrayList.sort(function (a, b) {
				let fa = '',
					fb = ''

				if (isNameOnly) {
					fa = a.name.toLowerCase()
					fb = b.name.toLowerCase()
				} else {
					fa = a.playlist_name.toLowerCase()
					fb = b.playlist_name.toLowerCase()
				}

				if (fa < fb) {
					return -1
				}
				if (fa > fb) {
					return 1
				}
				return 0
			})
		}
	}
	return []
}

export function playlistSortByPosition(arrayList) {
	if (arrayList) {
		if (arrayList.length > 0) {
			return arrayList.sort(function (a, b) {
				let fa = '',
					fb = ''

				fa = a.position
				fb = b.position

				if (fa < fb) {
					return -1
				}
				if (fa > fb) {
					return 1
				}
				return 0
			})
		}
	}
	return []
}

export function arrayListSortByKey(arrayList, key) {
	if (arrayList) {
		if (arrayList.length > 0) {
			return arrayList.sort(function (a, b) {
				let fa = '',
					fb = ''

				fa = a[key].toLowerCase()
				fb = b[key].toLowerCase()

				if (fa < fb) {
					return -1
				}
				if (fa > fb) {
					return 1
				}
				return 0
			})
		}
	}
	return []
}

export function tracklistSortByName(arrayList, isNameOnly = true) {
	if (arrayList) {
		if (arrayList.length > 0) {
			return arrayList.sort(function (a, b) {
				let fa = '',
					fb = ''

				if (isNameOnly) {
					fa = a.name.toLowerCase()
					fb = b.name.toLowerCase()
				} else {
					fa = a.track.song_title.toLowerCase()
					fb = b.track.song_title.toLowerCase()
				}

				if (fa < fb) {
					return -1
				}
				if (fa > fb) {
					return 1
				}
				return 0
			})
		}
	}
	return []
}

export function tracklistSortByPosition(arrayList) {
	if (arrayList) {
		if (arrayList.length > 0) {
			return arrayList.sort(function (a, b) {
				let fa = '',
					fb = ''

				//property position is always zero so we will use id for standard sorting since the original response is ordered by id ascending
				fa = a.id
				fb = b.id

				if (fa < fb) {
					return -1
				}
				if (fa > fb) {
					return 1
				}
				return 0
			})
		}
	}
	return []
}