import axios from 'axios';
import { useWindowDimensions, Image, View, Pressable } from 'react-native'
import { useEffect, useState } from 'react'
import { Ionicons } from '@expo/vector-icons'

import useLayout from '../../../Hooks/useLayout'
import LinkButton from './components/LinkButton'
import useTokens from '../../../Hooks/useTokens'
import { USERTYPE_URL } from '../../../Helpers/variables';

export default function Sidebar() {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)
	const [isSuperUser, setIsSuperUser] = useState(false)
	const { getTokens } = useTokens()

	useEffect(() => {
		getUsertype()
		handleResize()
	}, [width])

	const getUsertype = async () => {
		const { access } = await getTokens()
		await axios.get(USERTYPE_URL, {
			headers: { Authorization: `JWT ${access}` }
		}).then(response => {
			if (response.data.isSuperAdmin) {
				setIsSuperUser(true)
			}	else {
				setIsSuperUser(false)
			}
		}).catch(err => {
			setIsSuperUser(false)
			console.log(err)
		})
	}

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}
	
	const { isSidebarOpen, toggleSidebar, deviceType } = useLayout()

	return (
		<View
			style={{
				flex: 1,
				justifyContent: 'space-between',
				alignItems: 'flex-start'
			}}
		>
			<View style={{
					width: '100%',
					alignItems: isWideScreen || isSidebarOpen ? 'flex-start' : 'center',
					paddingHorizontal: !isWideScreen && isSidebarOpen ? 10 : (isWideScreen ? 25 : 0)
				}}
			>
				<View style={{ paddingHorizontal: 0, marginBottom: 20, marginTop: 10 }}>
					{deviceType !== 1 ? (
						<Image
							source={require('../../../assets/music_mind_logo.png')}
							style={{
								width: 160,
								height: 60
							}}
							resizeMode={'contain'}
						/>
					) : (
						// <View style={{ flexDirection: 'row' }}>
						// 	<Heading style={{ color: colors.accent }}>Music</Heading>
						// 	<Heading>Mind</Heading>
						// </View>
						<Pressable onPress={toggleSidebar}>
							<Ionicons name={'ios-menu'} size={isWideScreen ? 30 : 25} color={'#ffffff'} />
						</Pressable>
					)}
				</View>
				<LinkButton open={isSidebarOpen} route="AdminUserScreen" title={'Brugere'} icon={'ios-people'} />
				<LinkButton open={isSidebarOpen} route="AdminUserSoonExpireScreen" title={'Status på udløb'} icon={'time'} />
				<LinkButton
					open={isSidebarOpen}
					route="AdminUserDeactivatedScreen"
					title={'Inaktive brugere'}
					icon={'remove-circle'}
				/>
				<LinkButton open={isSidebarOpen} route="AssignPlaylistUserScreen" title={'Tildel spillelister'} icon={'list'} />
				<LinkButton
					open={isSidebarOpen}
					route="AdminInternalPlaylistContainerScreen"
					title={'Internt miljø'}
					icon={'apps'}
				/>
				{isSuperUser && (
					<LinkButton
						open={isSidebarOpen}
						route="AdminTrackRequestScreen"
						title={'Anmodede sange'}
						icon={'briefcase'}
					/>
				)}
			</View>
		</View>
	)
}
