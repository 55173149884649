import { Image, ImageBackground, Modal, StyleSheet, Text, View, useWindowDimensions, ScrollView, FlatList } from 'react-native'
import { useEffect, useState } from 'react'
import { colors } from '../../Helpers/variables'
import { SecondaryButton } from '../Buttons/Button'
import { Heading } from '../Typography/Typography'
import { FlatGrid } from 'react-native-super-grid'

export const ImportHelpModal = ({ modalVisible, setModalVisible, info }) => {

	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	const getModalWidth = () => {
		if (isWideScreen) {
			if (width < 800) {
				return '70%'
			}

			return '40%'
		} else {
			return '80%'
		}
	}

	const styles = StyleSheet.create({
		centeredView: {
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center'
		},
		modalView: {
			margin: isWideScreen && width > 800 ? 20 : 10,
			backgroundColor: colors.primary,
			borderRadius: 20,
			borderColor: 'white',
			borderWidth: 2,
			padding: isWideScreen && width > 800 ? 35 : 20,
			shadowColor: 'gray',
			shadowOffset: {
				width: 0,
				height: 2
			},
			shadowOpacity: 0.25,
			shadowRadius: 4,
			elevation: 5,
			minWidth: getModalWidth(),
			maxWidth: getModalWidth(),
			height: '80%'
			// overflow: 'scroll'
			// minWidth: 200
			// alignItems: 'center',
			// justifyContent: 'center',
		},
		label: {
			marginBottom: 20,
			color: 'white',
			fontSize: isWideScreen && width > 800 ? 20 : 18,
			marginTop: isWideScreen && width > 800 ? 10 : 5
		},
		errorMessage: {
			color: 'red',
			marginBottom: 10
		}
	})

	return (
		<Modal
			animationType="slide"
			transparent={true}
			visible={modalVisible}
			onRequestClose={() => {
				setModalVisible(false)
			}}
		>
			<View style={styles.centeredView}>
				<View style={styles.modalView}>
					<ScrollView style={{ width: '100%' }}>
						<FlatList
							data={info}
							renderItem={({ item, index }) => (
								<View>
									{
										item.header !== "{empty}" &&
										<View style={{ width: '100%', marginTop: 20 }}>
											<Heading key={item.code + index} index={index} style={{ fontSize: isWideScreen && width > 800 ? 24 : 18 }}>{item.header}</Heading>
										</View>
									}
									<View>
										<Text style={{ marginTop: isWideScreen && width > 800 ? 30 : 20, fontSize: isWideScreen && width > 800 ? 18 : 14, color: 'white' }}>{item.description}</Text>
									</View>
									{item.Photo &&
										<View>
											<Image
												resizeMode='center'
												source={{ uri: item.Photo }}
												style={{
													width: '100%',
													height: isWideScreen ? 300 : (isSmallScreen ? 250 : 300),
													marginBottom: 10,
													borderWidth: 1,
													borderColor: 'gray'
												}}
											/>
										</View>
									}
								</View>
							)}
							ListEmptyComponent={<Text style={{ color: 'white' }}>Empty Data...</Text>}
							keyExtractor={item => 'helpItem' + item.id}
							itemDimension={isWideScreen && width > 800 ? 200 : 100}
							showsVerticalScrollIndicator={false}
							showsHorizontalScrollIndicator={false}
						/>
					</ScrollView>
					<View style={{
						justifyContent: isWideScreen && width > 800 ? 'flex-end' : 'center',
						alignItems: 'center',
						marginTop: isWideScreen && width > 800 ? 20 : 10
					}}
					>
						<SecondaryButton title={'Luk'} onPress={() => setModalVisible(false)} style={{ width: isWideScreen && width > 800 ? 180 : 150, margin: isWideScreen ? 10 : 5 }} />
					</View>
				</View>
			</View>
		</Modal>
	)
}
