import { Ionicons } from '@expo/vector-icons'
import { useEffect, useState } from 'react'
import { Linking, Platform, Text, TouchableOpacity, View, useWindowDimensions } from 'react-native'
import { MenuDivider } from 'react-native-material-menu'
import { Menu, MenuOptions, MenuTrigger } from 'react-native-popup-menu'
import { colors } from '../../Helpers/variables'
import { Select } from './Select'
import NewItemLabel from '../Layouts/NewItemLabel'


export const Filter = ({ onSortValueChange, value, items, defaultButtonText, newItemLabelName='' }) => {
    const { width, height } = useWindowDimensions();
    const [isWideScreen, setIsWideScreen] = useState(width >= 700)
    const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

    const handleResize = () => {
        setIsWideScreen(width >= 700)
        setIsSmallScreen(width <= 320)
    }

    useEffect(() => {
        handleResize()
    }, [width, height])

    const [isOpened, setIsOpened] = useState(false)

    return (
        <View style={{ alignItems: 'center', justifyContent: 'center' }}>
            <Menu
                opened={isOpened}
                onBackdropPress={() => {
                    setIsOpened(false)
                }}
            >
                <MenuTrigger
                    onPress={() => {
                        setIsOpened(true)
                    }}
                >
                    <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                        {/* {
                            newItemLabelName &&
                                <NewItemLabel 
                                    // newItemLabelName={newItemLabelName} 
                                    fontSize={10} 
                                />
                        } */}
                        <Ionicons name="filter-outline" size={isWideScreen ? 32 : 22} color="white" />
                    </View>
                </MenuTrigger>
                <MenuOptions style={{ backgroundColor: colors.secondary, padding: 15 }}>
                    <View>
                        <Text style={{ fontSize: 16, color: 'white', marginLeft: 10, marginBottom: 10 }}>Filtrer visning: </Text>
                        <Select
                            width={'100%'}
                            onValueChange={onSortValueChange}
                            value={value}
                            items={items}
                            defaultButtonText={defaultButtonText}
                        />
                    </View>
                    {/* <MenuDivider color="gray" /> */}
                </MenuOptions>
            </Menu>
        </View>
    )
}
