import { useEffect, useState } from 'react'
import { View, useWindowDimensions } from 'react-native'
import { colors } from '../../Helpers/variables'
import { PrimaryButton } from '../Buttons/Button'
import { Input } from '../Forms/Input'

export const SearchInput = ({ _handleSearch }) => {
	const [input, setInput] = useState('')
	const { width } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width > 700)

	const handleResize = () => {
		setIsWideScreen(width > 700)
	}

	useEffect(() => {
		handleResize()
	}, [width])

	return (
		<View style={{ flexDirection: 'row', alignItems: 'center', width: '100%' }}>
			<Input
				autoFocus={true}
				onChangeText={text => setInput(text)}
				style={{
					width: isWideScreen ? '100%' : '75%',
					backgroundColor: colors.secondary,
					paddingHorizontal: isWideScreen ? 10 : 5,
					paddingVertical: isWideScreen ? 10 : 5,
					marginBottom: 0
				}}
				placeholder={'Indtast din søgning'}
				onSubmitEditing={() => _handleSearch(input)}
				value={input}
			/>
			<View style={{ marginLeft: isWideScreen ? 5 : 3 }}>
				<PrimaryButton
					style={{ width: 65, minHeight: isWideScreen ? 45 : 30, height: isWideScreen ? 45 : 30, marginVertical: 0 }}
					onPress={() => _handleSearch(input)}
					title="Søg" />
			</View>
		</View>
	)
}
