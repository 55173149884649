import { useEffect, useState } from 'react'
import { ImageBackground, Platform, Pressable, Text, View, useWindowDimensions } from 'react-native'
import { InfoButton } from '../../../Components/Buttons/Button'
import { Popup } from '../../../Components/Playlists/Popup'
import useFiles from '../../../Hooks/useFiles'

export default function CategoryCard({ item, wasDataCached, navigate, deviceType, newWidth = undefined }) {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	const customWidth = newWidth ? newWidth : (deviceType === 1 ? 240 : 190)

	const { getPhoto, isImage } = useFiles()

	const [image, setImage] = useState(item ? item.Photo : null)
	const fallback = require('../../../assets/fallback_cat.png')
	const [modalVisible, setModalVisible] = useState(false)

	useEffect(() => {
		if (Platform.OS !== 'web' && wasDataCached) {
			if (isImage(item.Photo)) {
				setImage(getPhoto(item.Photo))
			} else {
				setImage(null)
			}
		}
	}, [])

	return (
		<Pressable onPress={() => {
			navigate('PlaylistScreen', { categoryId: item.id, hasCopyPlaylistBtn: true, categoryName: item.name })
		}
		}>
			<View
				style={{
					width: customWidth,
					borderRadius: 10,
					marginBottom: 15,
					marginHorizontal: isWideScreen ? 10 : 5,
					// overflow: 'hidden',
					justifyContent: 'center',
					alignItems: 'center',
					paddingBottom: 10
				}}
			>
				<ImageBackground
					style={{ width: customWidth, height: customWidth, borderRadius: 10, overflow: 'hidden' }}
					resizeMode="cover"
					onError={() => console.log('Error loading image...')}
					source={image ? { uri: image } : fallback}
				>
					{item.description && <InfoButton size={24} fontSize={isWideScreen ? 16 : 14} setModalVisible={setModalVisible} />}
				</ImageBackground>
				<View style={{ width: customWidth - 20 }}>
					<Text style={{ color: 'white', fontSize: isWideScreen ? 17 : 14, fontWeight: 'bold', 
						textAlign: 'center', marginTop: 'auto', marginBottom: 'auto' }} ellipsizeMode={'tail'} numberOfLines={3}>
						{item.name}
					</Text>
				</View>
			</View>
			{
				modalVisible && (
					<Popup modalVisible={modalVisible} setModalVisible={setModalVisible} description={item.description} />
				)
			}
		</Pressable >
	)
}
