import React, { useState, useEffect } from 'react'
import { Modal, StyleSheet, View , useWindowDimensions } from 'react-native'
import { colors } from '../../Helpers/variables'
import FeedbackScreen from '../../Screens/FeedbackScreen/FeedbackScreen'

const FeedbackModal = ({ modalVisible, setModalVisible }) => {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	useEffect(() => {
		handleResize()
	}, [width, height])

	const trackStyles = StyleSheet.create({
		centeredView: {
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center'
		},
		modalView: {
			margin: isWideScreen ? 20 : 10,
			backgroundColor: colors.secondary,
			borderRadius: 20,
			padding: 35,
			shadowColor: '#000',
			shadowOffset: {
				width: 0,
				height: 2
			},
			shadowOpacity: 0.25,
			shadowRadius: 4,
			elevation: 5,
			width: isWideScreen ? '40%' : (isSmallScreen ? '80%' : '70%'),
			height: isWideScreen ? '80%' : '70%'
		},
		label: {
			marginBottom: 20,
			color: 'white',
			fontSize: 20,
			marginTop: 10
		},
		errorMessage: {
			color: 'red',
			marginBottom: 10
		}
	})
	
	return (
		<View style={trackStyles.centeredView}>
			<Modal
				animationType="slide"
				transparent={true}
				visible={modalVisible}
				onRequestClose={() => {
					setModalVisible(false)
				}}
			>
				<View style={trackStyles.centeredView}>
					<View style={trackStyles.modalView}>
						<FeedbackScreen setModalVisible={setModalVisible} />
					</View>
				</View>
			</Modal>
		</View>
	)
}

export default FeedbackModal
