import { useEffect, useState } from 'react'
import { Alert, FlatList, Platform, Text, useWindowDimensions, View } from 'react-native'
import * as Sentry from 'sentry-expo'
import { PrimaryButton } from '../../Components/Buttons/Button'

import Loader from '../../Components/Loader/Loader'
import InternalPlaylistContainerModal from '../../Components/Modals/InternalPlaylistContainerModal'
import Playlist from '../../Components/Playlists/Playlist'

import { colors, INTERNAL_CONTAINER_URL } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'

export default function AdminInternalPlaylistContainerScreen({ navigation }) {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	const api = useAxios()
	const [playlist, setPlaylist] = useState([])
	const [loading, setLoading] = useState(true)
	const [isModalOpen, setModalOpen] = useState(false)
	const [playlistInfo, setPlaylistInfo] = useState({})
	const [processType, setProcessType] = useState('update')

	const handleEditPlaylist = item => {
		setModalOpen(true)
		setPlaylistInfo(item)
	}

	const getPlaylist = () => {
		api
			.get(INTERNAL_CONTAINER_URL)
			.then(({ data }) => {
				setPlaylist(data)
			})
			.catch(error => {
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			})
			.finally(() => setLoading(false))
	}

	const handleDeletePlaylist = item => {
		api
			.delete(INTERNAL_CONTAINER_URL + item.id)
			.then(response => {
				if (response.status === 204) {
					getPlaylist()
					Alert.alert('Slet intern beholder', 'Fjernet', [{ text: 'OK', onPress: () => console.log('OK Pressed') }])
				} else {
					Alert.alert('Slet intern beholder', 'Kunne ikke fjernes', [
						{ text: 'OK', onPress: () => console.log('OK Pressed') }
					])
				}
			})
			.catch(err => {
				Alert.alert('Slet intern beholder', 'Kunne ikke fjernes', [
					{ text: 'OK', onPress: () => console.log('OK Pressed') }
				])
				console.log(err)
			})
			.finally(() => setLoading(false))
	}

	useEffect(() => {
		getPlaylist()
	}, [])

	return loading ? (
		<Loader />
	) : (
		<View style={{ backgroundColor: colors.primary, height: height - 130 }}>
			<View>
				<PrimaryButton
					title={'Tilføj kategori'}
					onPress={() => {
						setModalOpen(true)
						setProcessType('add')
						setPlaylistInfo({})
					}}
					style={{ width: isWideScreen ? 200 : 120, minHeight: isWideScreen ? 35 : 30 }}
				/>
			</View>
			<FlatList
				data={playlist}
				ListEmptyComponent={
					<View>
						<Text style={{ color: 'white', marginTop: isWideScreen ? 30 : 20, fontSize: isWideScreen ? 20 : 18 }}>Ingen spillelister.</Text>
					</View>
				}
				renderItem={({ item }) => (
					<Playlist
						playlist={item}
						handleEditPlaylist={handleEditPlaylist}
						hasDeleteBtn={true}
						handleDeletePlaylist={handleDeletePlaylist}
					/>
				)}
				showsVerticalScrollIndicator={false}
				showsHorizontalScrollIndicator={false}
				keyExtractor={item => item.id}
				contentContainerStyle={{ paddingBottom: isWideScreen ? 80 : 50 }}
			/>
			{isModalOpen && (
				<InternalPlaylistContainerModal
					modalVisible={isModalOpen}
					setModalVisible={setModalOpen}
					playlistInfo={playlistInfo}
					getPlaylist={getPlaylist}
					processType={processType}
					setProcessType={setProcessType}
					playlist={playlist}
				/>
			)}
		</View>
	)
}
