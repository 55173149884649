import { useEffect, useState } from 'react'
import { Platform, ScrollView, Text, useWindowDimensions, View } from 'react-native'
import { FlatGrid } from 'react-native-super-grid'
import * as Sentry from 'sentry-expo'
import Loader from '../../Components/Loader/Loader'
import { TherapistModal } from '../../Components/Modals/TherapistModal'
import { colors, MUSICTHERAPISTS_URL } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'
import Therapist from './Components/Therapist'

export default function MusicTherapistsScreen({ navigation }) {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	const api = useAxios()
	const [therapistList, setTherapistList] = useState([])
	const [loading, setLoading] = useState(true)

	const [therapistModal, setTherapistModal] = useState(false)
	const [therapistInfo, setTherapistInfo] = useState(undefined)

	const getTherapistList = async () => {
		await api
			.get(MUSICTHERAPISTS_URL)
			.then(({ data }) => {
				setTherapistList(data)
			})
			.catch(error => {
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			})
			.finally(() => setLoading(false))
		setLoading(false)
	}

	const handleViewTherapist = item => {
		setTherapistInfo(item)
		setTherapistModal(true)
	}

	useEffect(() => {
		getTherapistList()
	}, [])

	return loading ? (
		<Loader />
	) : (
		<View style={{ backgroundColor: colors.primary, flex: 1 }}>
			<ScrollView>
				<FlatGrid
					data={therapistList}
					renderItem={({ item, index }) => (
						<Therapist
							key={item.name + index}
							therapist={item}
							handleViewTherapist={handleViewTherapist}
							index={index}
						/>
					)}
					ListEmptyComponent={<Text style={{ color: 'white' }}>Ingen terapeuter...</Text>}
					keyExtractor={item => 'gridtherapist' + item.id}
					itemDimension={isWideScreen && width > 800 ? 200 : 100}
					showsVerticalScrollIndicator={false}
					showsHorizontalScrollIndicator={false}
				/>
				{therapistModal && (
					<TherapistModal modalVisible={therapistModal} setModalVisible={setTherapistModal} info={therapistInfo} />
				)}
			</ScrollView>
		</View>
	)
}
