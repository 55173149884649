import { useEffect, useState } from 'react'
import { Text, View, useWindowDimensions } from 'react-native'
import truncateStr from '../../Helpers/truncateStr'
import { PrimaryButton } from '../Buttons/Button'
import { styles } from './PlaylistStyles'

const Playlist = ({
	playlist,
	handleEditPlaylist,
	handleDeletePlaylist,
	buttonTitle = 'Rediger',
	hasDeleteBtn = false,
	...props
}) => {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	useEffect(() => {
		handleResize()
	}, [width, height])

	useEffect(() => {
		handleResize()
	}, [width, height])

	return (
		<View style={styles.playlistRow} {...props}>
			<View style={[styles.rowContainer, {width: isWideScreen ? '75%' : (isSmallScreen ? '60%' : '70%') }]}>
				<Text style={styles.albumTitle}>{truncateStr(playlist.name, 45)}</Text>
			</View>
			<View style={[styles.buttonContainer, {
					flexDirection: 'row',
					paddingRight: 5, 
					width: isWideScreen ? '25%' : (isSmallScreen ? '40%' : '30%')
				}]}
			>
				<PrimaryButton 
					style={{ 
						width: isWideScreen ? 120 : (hasDeleteBtn ? 50 : 100), 
						minHeight: isWideScreen ? 35 : 30 
					}} 
					title={buttonTitle} 
					onPress={() => handleEditPlaylist(playlist)}
				/>
				{hasDeleteBtn && (
					<PrimaryButton title={'Slet'}
						onPress={() => handleDeletePlaylist(playlist)} 
						style={{ 
							width: isWideScreen ? 120 : (hasDeleteBtn ? 50 : 100), 
							minHeight: isWideScreen ? 35 : 30 
						}}
					/>
				)}
			</View>
		</View>
	)
}

export default Playlist
