import { View, ActivityIndicator } from 'react-native'
import { colors } from '../../Helpers/variables'

export default function Loader({ bgColor = colors.primary }) {
	return (
		<View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: bgColor, padding: 30 }}>
			<ActivityIndicator size="large" color={colors.accent} />
		</View>
	)
}
