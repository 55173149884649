import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Platform } from 'react-native'
import TrackPlayer, { Event, RepeatMode, useTrackPlayerEvents } from 'react-native-track-player'
import * as Sentry from 'sentry-expo'
import { ROYALTY_LOGGING_URL } from '../../Helpers/variables'
import useTokens from '../../Hooks/useTokens'

export const SoundContext = React.createContext()

export const SoundProvider = ({ children }) => {
	const [oldTrackList, setOldTrackList] = useState([])
	const [trackList, setTrackList] = useState([])
	const [currentPlayingTrack, setCurrentPlayingTrack] = useState(null)
	const [currentPlayingTrackIndex, setCurrentPlayingTrackIndex] = useState(0)
	const [isShuffleActive, setIsShuffleActive] = useState(false)
	const [repeatType, setRepeatType] = useState('none')
	const [repeatOnce, setRepeatOnce] = useState(false)
	const [volume, setVolume] = useState(100)

	const [mediaPlayerAcquisition, setMediaPlayerAcquisition] = useState({
		isShuffleBtnVisible: true,
		isRepeatBtnVisible: true
	})

	const { getTokens } = useTokens()
	let [isRoyaltyLogSent, setIsRoyaltyLogSent] = useState(false)

	const postRoyaltyLogging = async id => {
		console.log('postRoyaltyLogging')
		if (isRoyaltyLogSent) return

		isRoyaltyLogSent = true
		setIsRoyaltyLogSent(true)

		const { access } = await getTokens()
		axios
			.post(
				ROYALTY_LOGGING_URL + id,
				{},
				{
					headers: { Authorization: `JWT ${access}` }
				}
			)
			.then(response => {
				if (response.status === 201) console.log('Success royalty logging...')
				else console.log('Failed royalty logging...')
			})
	}

	const addTracksToPlayer = async (tracksList, isReset) => {
		console.log('addTracksToPlayer')
		if (isReset) await TrackPlayer.reset()
		let newTracks = []
		tracksList.map(item => {
			let track_file = item.track?.track_file || item.track_file
			if(track_file) {
				let trackObject = getTrackObject(item.track || item)
				newTracks.push(trackObject)
			}
		})
		// console.log('TrackPlayer.add(newTracks): ' + newTracks.length)
		await TrackPlayer.add(newTracks)
	}

	const getTrackObject = track => {
		console.log('getTrackObject')
		return {
			url: track.track_file,
			title: track.song_title,
			artist: track.artist_name,
			album: track.album_name,
			genre: track.genre,
			artwork: track.album_photo
		}
	}

	const addTrack = async (track, insertBeforeIndex = 0) => {
		console.log('addTrack')
		let trackObject = getTrackObject(track)
		await TrackPlayer.add(trackObject, insertBeforeIndex)
	}

	const addTracks = async (tracksList, isReset = true) => {
		console.log('addTracks')
		setCurrentPlayingTrack(null)
		await addTracksToPlayer(tracksList, isReset)
		// setOldTrackList(trackList)
		setTrackList(tracksList)
	}

	const loadTrack = async track => {
		console.log('loadTrack')
		let trackObject = getTrackObject(track)
		await TrackPlayer.load(trackObject)
	}

	const playTrack = async (track, trackIndex = currentPlayingTrackIndex, isLoadNewTrack = true) => {
		console.log('playTrack')
		if (trackIndex !== currentPlayingTrackIndex) {
			await TrackPlayer.skip(trackIndex)
		}
		setCurrentPlayingTrackIndex(trackIndex)
		setCurrentPlayingTrack(track)
		await TrackPlayer.play()
	}

	const pauseTrack = async () => {
		console.log('pauseTrack')
		await TrackPlayer.pause()
	}

	const removeTrack = async trackIndex => {
		console.log('removeTrack')
		await TrackPlayer.remove(trackIndex)
	}

	const setCurrentTrack = async (type = 'next') => {
		if (type === 'next') {
			console.log('setCurrentTrack')
			if (trackList.length > 0) {
				let playerCurrentTrackIndex = currentPlayingTrackIndex
				console.log('playerCurrentTrackIndex: ' + playerCurrentTrackIndex)
				let activeIndex = await TrackPlayer.getActiveTrackIndex()
				console.log('activeIndex: ' + activeIndex)
				let newTrackIndex = activeIndex
				if (newTrackIndex === trackList.length - 1) {
					if (repeatType !== 'none' && repeatType !== 'one') {
						newTrackIndex = 0
					}
				}
				setCurrentPlayingTrackIndex(newTrackIndex)
				setCurrentPlayingTrack(trackList[newTrackIndex]?.track || trackList[newTrackIndex])
			}
		}
	}

	const nextTrack = async () => {
		console.log('nextTrack')
		await TrackPlayer.skipToNext()
	}

	const previousTrack = async () => {
		console.log('previousTrack')
		let newTrackIndex = currentPlayingTrackIndex
		if (newTrackIndex > 0) newTrackIndex = newTrackIndex - 1
		else newTrackIndex = trackList.length - 1
		setCurrentPlayingTrackIndex(newTrackIndex)
		setCurrentPlayingTrack(trackList[newTrackIndex].track || trackList[newTrackIndex])
		await TrackPlayer.skipToPrevious()
	}

	/*
	 * function handleSeek()
	 *
	 * @description: Handle track position change
	 * if the track is playing, update the position
	 * according to the passed in position value from the track player component
	 *
	 * @param position {number} - milliseconds
	 */
	const handleSeek = async position => {
		try {
			console.log('handleSeek')
			await TrackPlayer.seekTo(position)
		} catch (error) {
			if (Platform.OS === 'web') Sentry.Browser.captureException(error)
			else Sentry.Native.captureException(error)
		}
	}

	/*
	 * function handleClickShuffle()
	 *
	 * @description: Determines if shuffle or unshuffle of tracks list
	 */
	const handleClickShuffle = () => {
		if (trackList.length > 0) {
			console.log('handleClickShuffle')
			if (!isShuffleActive) {
				setIsShuffleActive(true)
				shuffle()
			} else {
				setIsShuffleActive(false)
				let currentUniqueId = trackList[currentPlayingTrackIndex].id
				let currentTrackIndex =
					oldTrackList.length > 0
						? oldTrackList.findIndex(x => x.id === currentUniqueId)
						: trackList.findIndex(x => x.id === currentUniqueId)
				setCurrentPlayingTrackIndex(currentTrackIndex)
				addTracksToPlayer(oldTrackList)
				setTrackList(oldTrackList)
				setOldTrackList([])
			}
		}
	}

	/*
	 * function generateRandomIndex()
	 *
	 * @description: Generate random integer from 0 to total length of track list
	 */
	const generateRandomIndex = () => {
		let randomNum = Math.floor(Math.random() * trackList.length + 1)
		randomNum = randomNum === trackList.length ? randomNum - 1 : randomNum
		return randomNum
	}

	/*
	 * function shuffle()
	 *
	 * @description: Handles the shuffling of tracks list
	 */
	const shuffle = async () => {
		let currentIndex = trackList.length,
			temporaryValue,
			randomIndex
		// let trackListIndexShuffleTemp = []
		let trackListTemp = []

		// While there remain elements to shuffle...
		while (0 !== currentIndex) {
			// Pick a remaining element...
			randomIndex = generateRandomIndex()
			if (currentIndex === 1) {
				//Find values that are in trackList but not in trackListIndexShuffleTemp
				let result = trackList.filter(x => x.id === trackList[currentPlayingTrackIndex].id)
				trackListTemp = trackListTemp.filter(x => x.id !== trackList[currentPlayingTrackIndex].id)
				trackListTemp.unshift(result[0])
				currentIndex -= 1
			} else {
				let checkIfExist = trackListTemp.filter(x => x.id === trackList[randomIndex].id)
				if (checkIfExist.length === 0) {
					currentIndex -= 1
					trackListTemp.push(trackList[randomIndex])
				}
			}
		}
		if (trackListTemp.length > 0) {
			setOldTrackList(trackList)
			await TrackPlayer.removeUpcomingTracks()
			let addTracksToTrackPlayer = trackListTemp.filter(x => x.id !== trackListTemp[0].id)
			addTracksToPlayer(addTracksToTrackPlayer)
			setTrackList(trackListTemp)
			setCurrentPlayingTrackIndex(0)
		}
	}

	/*
	 * function handleClickRepeat()
	 *
	 * @description: Handles the behavior of repeat type(repeat all, repeat one)
	 */
	const handleClickRepeat = async repeatType => {
		console.log('handleClickRepeat')
		if (repeatType === 'none') {
			await TrackPlayer.setRepeatMode(RepeatMode.Off)
		} else if (repeatType === 'repeatAllTracks' || repeatType === 'all') {
			await TrackPlayer.setRepeatMode(RepeatMode.Queue)
		} else {
			await TrackPlayer.setRepeatMode(RepeatMode.Track)
		}
		setRepeatType(repeatType)
		setRepeatOnce(repeatType === 'one' ? true : false)
	}

	/*
	 * function handleChangeVolume()
	 *
	 * @description: Handles the volume change
	 */
	const handleChangeVolume = async volume => {
		console.log('handleChangeVolume')
		let newVolume = volume > 0 ? volume / 100 : volume
		try {
			await TrackPlayer.setVolume(newVolume)
			setVolume(volume)
		} catch (error) {
			if (Platform.OS === 'web') Sentry.Browser.captureException(error)
			else Sentry.Native.captureException(error)
		}
	}

	const clearQueue = async () => {
		console.log('clearQueue')
		await TrackPlayer.reset()
		setCurrentPlayingTrack(null)
		setCurrentPlayingTrackIndex(0)
	}

	useTrackPlayerEvents([Event.PlaybackActiveTrackChanged, Event.PlaybackQueueEnded], async event => {
		if (event.type === Event.PlaybackQueueEnded) {
			console.log('Playback queue ended. No more songs in the queue.')
			await TrackPlayer.pause()
			await TrackPlayer.seekTo(0)
		} else if (event.type === Event.PlaybackActiveTrackChanged && currentPlayingTrack) {
			console.log('Event.PlaybackActiveTrackChanged')
			setCurrentTrack()
		}
	})

	return (
		<SoundContext.Provider
			value={{
				trackList,
				setTrackList,
				currentPlayingTrack,
				setCurrentPlayingTrack,
				isShuffleActive,
				repeatType,
				mediaPlayerAcquisition,
				setMediaPlayerAcquisition,
				volume,
				currentPlayingTrackIndex,
				setIsShuffleActive,
				setCurrentPlayingTrackIndex,
				addTrack,
				loadTrack,
				addTracks,
				playTrack,
				pauseTrack,
				removeTrack,
				nextTrack,
				previousTrack,
				handleSeek,
				handleClickShuffle,
				handleClickRepeat,
				handleChangeVolume,
				postRoyaltyLogging,
				setCurrentTrack,
				clearQueue,
				addTracksToPlayer
			}}
		>
			{children}
		</SoundContext.Provider>
	)
}
