import { getHeaderTitle } from '@react-navigation/elements'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { colors } from '../../Helpers/variables'
import AlbumTracksScreen from '../../Screens/AlbumTracksScreen/AlbumTracksScreen'
import ArtistTracksScreen from '../../Screens/ArtistTracksScreen/ArtistTracksScreen'
import CreatePlaylistScreen from '../../Screens/CreatePlaylistScreen/CreatePlaylistScreen'
import GenreTracksScreen from '../../Screens/GenreTracksScreen/GenreTracksScreen'
import HomeScreen from '../../Screens/HomeScreen/HomeScreen'
import InternalContainerPlaylistsScreen from '../../Screens/InternalContainerPlaylistsScreen/InternalContainerPlaylistsScreen'
import InternalContainerScreen from '../../Screens/InternalContainerScreen/InternalContainerScreen'
import MusicTherapistsScreen from '../../Screens/MusicTherapistScreen/MusicTherapistsScreen'
import MyPlaylistScreen from '../../Screens/MyPlaylistScreen/MyPlaylistScreen'
import PlaylistScreen from '../../Screens/PlaylistScreen/PlaylistScreen'
import PlaylistTracksScreen from '../../Screens/PlaylistTracksScreen/PlaylistTracksScreen'
import SearchScreen from '../../Screens/SearchScreen/SearchScreen'
import SongRequestScreen from '../../Screens/SongRequestScreen/SongRequestScreen'
import TracksScreen from '../../Screens/TracksScreen/TracksScreen'
import YearTracksScreen from '../../Screens/YearTracksScreen/YearTracksScreen'
import ComposerTracksScreen from '../../Screens/ComposerTracksScreen/ComposerTracksScreen'
import AuthService from '../../Services/AuthService'
import Header from '../Layouts/Header'
import MainLayout from '../Layouts/MainLayout'
import { NotificationModal } from '../Modals/NotificationModal'
// import { useIdleTimer } from 'react-idle-timer'

const Stack = createNativeStackNavigator()

export default function MainStack() {
	return (
		<MainLayout>
			<Stack.Navigator
				initialRouteName={'Home'}
				screenOptions={{
					header: ({ navigation, route, options }) => (
						<Header navigation={navigation} title={getHeaderTitle(options, route.name)} />
					),
					headerStyle: { backgroundColor: colors.primary },
					headerBackTitleVisible: false,
					headerShadowVisible: false,
					headerTintColor: 'white',
					contentStyle: { backgroundColor: colors.primary }
				}}
			>
				<Stack.Screen options={{ title: 'Hjem' }} name="HomeScreen" component={HomeScreen} />
				<Stack.Screen
					options={{ title: 'Internt Miljø' }}
					name="InternalContainerScreen"
					component={InternalContainerScreen}
				/>
				<Stack.Screen options={{ title: 'Spillelister' }} name="PlaylistScreen" component={PlaylistScreen} />
				<Stack.Screen options={{ title: 'Mine Spillelister' }} name="MyPlaylistsScreen" component={MyPlaylistScreen} />
				<Stack.Screen
					options={{ title: 'Internt Spillelister' }}
					name="InternalPlaylistScreen"
					component={InternalContainerPlaylistsScreen}
				/>
				<Stack.Screen options={{ title: 'Spilleliste numre' }} name="TracksScreen" component={TracksScreen} />
				<Stack.Screen options={{ title: 'Søg' }} name="SearchScreen" component={SearchScreen} />
				<Stack.Screen
					options={{ title: 'Musikterapeuter' }}
					name="MusicTherapistsScreen"
					component={MusicTherapistsScreen}
				/>
				<Stack.Screen name="ArtistTracks" options={{ title: 'Kunstner Sange' }} component={ArtistTracksScreen} />
				<Stack.Screen name="AlbumTracks" options={{ title: 'Album Sange' }} component={AlbumTracksScreen} />
				<Stack.Screen name="YearTracks" options={{ title: 'Year Sange' }} component={YearTracksScreen} />
				<Stack.Screen name="GenreTracks" options={{ title: 'Genre Sange' }} component={GenreTracksScreen} />
				<Stack.Screen name="PlaylistTracks" options={{ title: 'Playlist Sange' }} component={PlaylistTracksScreen} />
				<Stack.Screen name="ComposerTracks" options={{ title: 'Komponist Sange' }} component={ComposerTracksScreen} />
				<Stack.Screen
					name="CreatePlaylistScreen"
					options={{ title: 'Opret Spilleliste' }}
					component={CreatePlaylistScreen}
				/>
				<Stack.Screen name="SongRequestScreen" options={{ title: 'Anmod om en sang' }} component={SongRequestScreen} />
			</Stack.Navigator>
		</MainLayout>
	)
}
