import * as Device from 'expo-device'
import React, { useEffect, useState } from 'react'
import { useWindowDimensions } from 'react-native'

export const LayoutContext = React.createContext()

export const LayoutProvider = ({ children }) => {
	const {width} = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width > 700)
	const [isSidebarOpen, setIsSidebarOpen] = React.useState(true)
	const [deviceType, setDeviceType] = React.useState(0)
	const [isOffline, setIsOffline] = React.useState(false)
	const [isShowHeaderTour, setIsShowHeaderTour] = React.useState(false)
	const [isShowSearchBox, setIsShowSearchBox] = React.useState(false)
	const [shouldReset, setShouldReset] = React.useState(false)
	const toggleSidebar = () => {
		setIsSidebarOpen(prev => !prev)
	}

	const setOfflineOnlineStatus = isOffline => {
		setIsOffline(isOffline)
	}

	const handleResize = () => {
		setIsWideScreen(width > 700)
		setIsSidebarOpen(width > 700)
	}
	
	useEffect(() => {
		handleResize()
	}, [width])

	useEffect(() => {
		async function boot() {
			const device = await Device.getDeviceTypeAsync()
			setDeviceType(device)
			setIsSidebarOpen(width > 700)
		}
		boot()
	}, [])

	return (
		<LayoutContext.Provider
			value={{
				isSidebarOpen,
				toggleSidebar,
				deviceType,
				setOfflineOnlineStatus,
				isOffline,
				isShowHeaderTour,
				setIsShowHeaderTour,
				isShowSearchBox,
				setIsShowSearchBox,
				shouldReset,
				setShouldReset
			}}
		>
			{children}
		</LayoutContext.Provider>
	)
}
