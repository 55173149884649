import { StyleSheet, Text } from 'react-native'
import { RadioButton, RadioGroup } from 'react-native-flexi-radio-button'

export const styles = StyleSheet.create({
	radioContainer: {
		marginLeft: 25,
		marginTop: 5
	},
	labelStyle: {
		color: 'white'
	}
})

export default function RadioButtonGroup({ items, setRadioButtons, index, ...props }) {
	function onPressRadioButton(value) {
		setRadioButtons(value)
	}

	return (
		<RadioGroup
			size={24}
			thickness={2}
			color="white"
			selectedIndex={index}
			onSelect={(index, value) => onPressRadioButton(index)}
		>
			{items.map(item => (
				<RadioButton key={item.id  + item.label} value={item.value} color="white">
					<Text style={{ color: 'white' }}>{item.label}</Text>
				</RadioButton>
			))}
		</RadioGroup>
	)
}
