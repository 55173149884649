import { Modal, Platform, StyleSheet, View, useWindowDimensions } from 'react-native'
import { ANNOUNCEMENTS_URL, TERMS_CONDITIONS_URL, colors } from '../../Helpers/variables'
import NewFeedScreen from '../../Screens/NewsFeedScreen/NewsFeedScreen'
import { SecondaryButton } from '../Buttons/Button'
import { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios'
import { ScrollView } from 'react-native'
import { Text } from 'react-native'
import useTokens from '../../Hooks/useTokens'

const styles = StyleSheet.create({
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center'
	},
	modalView: {
		margin: 20,
		backgroundColor: colors.secondary,
		borderRadius: 20,
		padding: 35,
		shadowColor: 'gray',
		shadowOffset: {
			width: 0,
			height: 2
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
		minWidth: 350,
		maxWidth: 700,
		maxHeight: '90%'
	},
	label: {
		marginBottom: 20,
		color: 'white',
		fontSize: 20,
		marginTop: 10
	},
	errorMessage: {
		color: 'red',
		marginBottom: 10
	},
	header: {
		color: 'white',
		fontSize: 28,
		fontWeight: 'bold',
		paddingLeft: 20,
		paddingTop: 10
	},
	body: {
		color: 'white',
		fontSize: 18,
		paddingLeft: 20,
		paddingLeft: 20,
		paddingRight: 20
	}
})

const AnnouncementsModal = ({
	modalVisible,
	// setModalVisible,
	handleAgreeAnnouncements
}) => {
	const [announcements, setAnnouncments] = useState(undefined)
	const { getTokens } = useTokens()
	const { width, height } = useWindowDimensions();

	const checkPortraitScreen = () => {
		return (height > 500 && height <= 700 && width <= 400)
	}

	const [isPortrait, setIsPortrait] = useState(checkPortraitScreen())

	const handleResize = () => {
		setIsPortrait(checkPortraitScreen())
	}

	useEffect(() => {
		handleResize()
	}, [width, height])

	const getAnnouncments = async () => {
		console.log('getAnnouncments')
		const { access } = await getTokens()
		try {
			const { data, status } = await axios.get(ANNOUNCEMENTS_URL, {
				headers: { Authorization: `JWT ${access}` }
			})
			if (data.length > 0) {
				setAnnouncments(data[0])
			}
		} catch (ex) {
			console.log(ex)
		}
	}

	useEffect(() => {
		getAnnouncments()
	}, [modalVisible])

	return !announcements ? undefined : (
		<Modal
			animationType="slide"
			transparent={true}
			visible={modalVisible}
			onRequestClose={() => {
				setModalVisible()
			}}
		>
			<View style={styles.centeredView}>
				<View style={styles.modalView}>
					<ScrollView style={{
						maxWidth: 580, minWidth: 300,
						paddingBottom: 15
					}}>
						{/* <Text style={styles.header}>{announcements.title}</Text> */}
						<Text style={styles.body}>{announcements.content}</Text>
					</ScrollView>
					<View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end', marginTop: 10 }}>
						<SecondaryButton
							title={'OK'}
							onPress={() => handleAgreeAnnouncements()}
							style={{ width: 180, margin: 10 }}
						/>
					</View>
				</View>
			</View>
		</Modal>
	)
}

export default AnnouncementsModal
