import { useNavigation } from '@react-navigation/native'
import React, { useCallback, useEffect, useState } from 'react'
import { Pressable, Text, TouchableHighlight, View } from 'react-native'
import PlayButton from '../../../Components/Layouts/Sidebar/components/TrackControls/PlayButton'
import truncateStr from '../../../Helpers/truncateStr'
import { colors } from '../../../Helpers/variables'
import useLayout from '../../../Hooks/useLayout'
import usePlaybackStatus from '../../../Hooks/usePlaybackStatus'
import useSound from '../../../Hooks/useSound'
import { trackStyles } from './TrackStyles'

export default function TrackLabelsView({ track, isQueue, tracksList, trackIndex, playlistTrackId, playlistId }) {
	const { deviceType } = useLayout()
	const { currentPlayingTrack, addTracks, playTrack, trackList, loadTrack } = useSound()

	const [playing, setPlaying] = useState(false)
	const [loaded, setLoaded] = useState(false)
	const navigation = useNavigation()
	const { setPlaybackStatus } = usePlaybackStatus()
	const isTrackFileValid = track.track_file !== null && track.track_file !== undefined && track.track_file !== ''

	const isFirstItem = trackIndex === 0

	const handlePressTrack = async () => {
		try {
			if (!isTrackFileValid) {
				return
			}
			// console.log('handlePressTrack: ' + playlistId + ' - ' + trackList[0]?.playlist)
			if (playlistId !== trackList[0]?.playlist || !currentPlayingTrack || (!playlistId && !trackList[0]?.playlist)) {
				// console.log('addTracks: ' + tracksList.length)
				await addTracks(tracksList)
			}

			// console.log('playTrack: ' + trackIndex)
			await playTrack(track, trackIndex)

			setPlaybackStatus({
				isPlaying: true
			})
		} catch (ex) {
			console.log('ERROR: ' + ex)
		}
	}

	const initialLoad = useCallback(async () => {
		setPlaying(currentPlayingTrack?.id === track?.id)
		setLoaded(currentPlayingTrack?.id === track?.id)
	}, [currentPlayingTrack?.id, track?.id])

	useEffect(() => {
		initialLoad()
	}, [currentPlayingTrack])

	const getTextColor = () => {
		if ((playing || loaded) && isTrackFileValid) {
			return colors.accent
		} else if (!isTrackFileValid) {
			return 'gray'
		} else {
			return 'white'
		}
	}

	const getTrackLabel = () => {
		if (isQueue) {
			return truncateStr(track.song_title, 45)
		} else {
			return `${trackIndex + 1}. ${truncateStr(track.song_title, 45)}`
		}
	}

	const getTrackComposer = composer => {
		if (composer?.toString()?.toLowerCase()?.trim() === 'not applicable') {
			return 'Mangler data'
		} else {
			return composer
		}
	}

	const formatMillisecondsToTime = milliseconds => {
		const totalSeconds = Math.round(milliseconds / 1000)
		const hours = Math.floor(totalSeconds / 3600)
		const minutes = Math.floor((totalSeconds % 3600) / 60)
		const seconds = Math.floor(totalSeconds % 60)

		const paddedHours = hours > 0 ? String(hours).padStart(2, '0') : '00'
		const paddedMinutes = String(minutes).padStart(2, '0')
		const paddedSeconds = String(seconds).padStart(2, '0')

		return hours > 0 ? `${paddedHours}:${paddedMinutes}:${paddedSeconds}` : `${paddedMinutes}:${paddedSeconds}`
	}

	return (
		<View style={{ flexDirection: 'row', flex: 12 }}>
			<Pressable
				onPress={() => {
					handlePressTrack()
				}}
				style={trackStyles.songContainer}
			>
				{isFirstItem && !isQueue && (
					<View
						style={{
							flexDirection: 'row',
							justifyContent: 'flex-start',
							width: '100%',
							borderBottomColor: 'rgba(237, 231, 225, .1)',
							borderBottomWidth: 0.1,
							marginBottom: 10
						}}
					>
						<Text style={[trackStyles.trackListHeader, { marginLeft: 40, width: '30%' }]}>Titel & artist</Text>
						{deviceType !== 1 && <Text style={[trackStyles.trackListHeader, { width: '10%' }]}>Varighed</Text>}
						{deviceType !== 1 && <Text style={[trackStyles.trackListHeader, { width: '30%' }]}>Album</Text>}
						{deviceType !== 1 && <Text style={[trackStyles.trackListHeader, { width: '20%' }]}>Komponist</Text>}
					</View>
				)}
				<View style={{ flexDirection: 'row' }}>
					{navigation && (
						<View style={{ marginRight: 10 }}>
							<PlayButton size={30} track={track} trackIndex={trackIndex} handlePressTrack={handlePressTrack} />
						</View>
					)}
					<View style={{ width: deviceType !== 1 ? '30%' : '90%' }}>
						<Text
							style={{
								color: getTextColor(),
								fontSize: 14
							}}
						>
							{getTrackLabel()}
						</Text>
						<TouchableHighlight
							onPress={() => {
								if (navigation)
									navigation.navigate('ArtistTracks', {
										artistId: track.artist,
										photo: track.album_photo,
										title: track.artist_name
									})
							}}
						>
							<View style={{ width: '80%' }}>
								<Text style={[trackStyles.albumTitle, { color: getTextColor() }]}>
									{truncateStr(track.artist_name, 45)}
								</Text>
							</View>
						</TouchableHighlight>
					</View>
					{deviceType !== 1 && (
						<View style={{ width: '10%' }}>
							<View>
								<Text
									style={{
										color: getTextColor(),
										fontSize: 13
									}}
								>
									{formatMillisecondsToTime(track.duration)}
								</Text>
							</View>
						</View>
					)}
					{deviceType !== 1 && (
						<View style={{ width: '30%' }}>
							<Pressable onPress={() => handlePressTrack()} style={trackStyles.albumContainer}>
								<TouchableHighlight
									onPress={() => {
										if (navigation)
											navigation.navigate('AlbumTracks', {
												albumId: track.album,
												photo: track.album_photo,
												title: track.album_name
											})
									}}
								>
									<View>
										<Text
											style={{
												color: getTextColor(),
												fontSize: 13
											}}
										>
											{truncateStr(track.album_name, 45)}
										</Text>
									</View>
								</TouchableHighlight>
							</Pressable>
						</View>
					)}
					{deviceType !== 1 && (
						<View style={{ width: '20%' }}>
							<View>
								<Text
									style={{
										color: getTextColor(),
										fontSize: 13
									}}
								>
									{getTrackComposer(track.composer)}
								</Text>
							</View>
						</View>
					)}
				</View>
			</Pressable>
		</View>
	)
}
