import { Ionicons } from '@expo/vector-icons'
import { useNavigation, useRoute } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { Platform, Pressable, View, useWindowDimensions } from 'react-native'
import { CopilotProvider, CopilotStep, useCopilot, walkthroughable } from 'react-native-copilot'
import * as Sentry from 'sentry-expo'
import useAudio from '../../Hooks/useAudio'
import useAuth from '../../Hooks/useAuth'
import useLayout from '../../Hooks/useLayout'
import useTimer from '../../Hooks/useTimer'
import AuthService from '../../Services/AuthService'
import { ConfirmationModal } from '../Modals/ConfirmationModal'
import { HelpMenu } from './HelpMenu'
import { MusicTherapistsMenu } from './MusicTherapistsMenu'
import NewsFeedMenu from './NewsFeedMenu'
import { SearchMenu } from './SearchMenu'
import useSound from '../../Hooks/useSound'

const WalkthroughableView = walkthroughable(View)
const SubHeader = props => {
	const route = useRoute()
	const { width } = useWindowDimensions()
	const [isWideScreen, setIsWideScreen] = useState(width > 700)

	const handleResize = () => {
		setIsWideScreen(width > 700)
	}

	useEffect(() => {
		handleResize()
	}, [width])

	const { isShowHeaderTour, setIsShowHeaderTour, setIsShowSearchBox, isShowSearchBox } = useLayout()

	const { setIsLoggedIn, setIsAdminViewOpen, isAdmin } = useAuth()
	const [logoutConfirmModal, setLogoutConfirmModal] = useState(false)
	const { clearTimeoutId } = useTimer()
	const navigation = useNavigation()
	const { copilotEvents } = useCopilot()
	const { clearQueue } = useSound()

	const handleStepChange = step => {
		console.log(`Current step is: ${step.order}`)
	}

	const handleSearch = async (text, categoryFilters) => {
		setIsShowSearchBox(true)
		navigation.navigate('SearchScreen', { searchInput: text, categoryFilters: categoryFilters })
	}

	const handleTherapistMenu = () => {
		navigation.navigate('MusicTherapistsScreen')
	}

	const handleAdminView = () => {
		global.previousScreen = route.name
		global.routeParams = route.params
		setIsAdminViewOpen(true)
	}

	const handleLogout = async () => {
		AuthService.logout()

		try {
			clearQueue()
		} catch (error) {
			if (Platform.OS === 'web') Sentry.Browser.captureException(error)
			else Sentry.Native.captureException(error)
		}
		setIsLoggedIn(false)
		setIsAdminViewOpen(false)
		clearTimeoutId()
	}

	const cancelLogoutConfirmModal = () => {
		setLogoutConfirmModal(false)
	}

	useEffect(() => {
		//setting a function to handle the step change event
		copilotEvents.on('stepChange', handleStepChange)

		if (isShowHeaderTour) {
			props.start()
			setIsShowHeaderTour(!isShowHeaderTour)
		}

		return () => {
			copilotEvents.off('stop')
			copilotEvents.off('stepChange')
			copilotEvents.off('start')
		}
	}, [isShowHeaderTour])

	return (
		<View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 0, paddingRight: 5 }}>
			<View style={{ flexDirection: 'row', alignItems: 'center' }}>
				<CopilotStep order={6} text={'Du kan søge efter sange her'} name={'ajwerweruthirteen'}>
					<WalkthroughableView>
						<SearchMenu handleSearch={handleSearch} />
					</WalkthroughableView>
				</CopilotStep>
				<CopilotStep
					order={1}
					text={'Denne knap indeholder opdateringer og ændringer vedrørende Musicmind'}
					name={'ajwerwerufourteen'}
				>
					<WalkthroughableView>
						<NewsFeedMenu />
					</WalkthroughableView>
				</CopilotStep>
				<CopilotStep order={2} text={'Denne knap indeholder en liste over musikterapeuter'} name={'ajwerwerufourteen'}>
					<WalkthroughableView>
						<MusicTherapistsMenu handleTherapistMenu={handleTherapistMenu} />
					</WalkthroughableView>
				</CopilotStep>
				{isAdmin && (
					<CopilotStep order={3} text={'Denne knap vil omdirigere dig til admin side'} name={'ajwerwerufifteen'}>
						<WalkthroughableView>
							<Pressable onPress={handleAdminView}>
								<Ionicons
									name="settings-outline"
									style={{ marginLeft: !isWideScreen ? 10 : 15 }}
									size={isWideScreen ? 30 : 22}
									color="white"
								/>
							</Pressable>
						</WalkthroughableView>
					</CopilotStep>
				)}
				<CopilotStep order={4} text={'Denne knap vil logge dig ud af Musicmind'} name={'ajwerwerusixteen'}>
					<WalkthroughableView>
						<Pressable
							onPress={() => {
								setLogoutConfirmModal(true)
							}}
						>
							<Ionicons
								name="power"
								style={{ marginLeft: !isWideScreen ? 10 : 15 }}
								size={isWideScreen ? 30 : 22}
								color="white"
							/>
						</Pressable>
					</WalkthroughableView>
				</CopilotStep>
				<CopilotStep
					order={5}
					text={'Indeholder brugermanual og feedbackformularmenuer'}
					name={'ajwerweruseventeen'}
					style={{ marginLeft: -5 }}
				>
					<WalkthroughableView>
						<HelpMenu />
					</WalkthroughableView>
				</CopilotStep>
				{logoutConfirmModal && (
					<ConfirmationModal
						handleSubmit={handleLogout}
						modalVisible={logoutConfirmModal}
						cancelCallback={cancelLogoutConfirmModal}
						description={'Er du sikker på du ønsker at logge ud af Musicimind?'}
						titleCancel={'Nej'}
						titleSubmit={'Ja'}
					/>
				)}
			</View>
		</View>
	)
}
export default React.memo(SubHeader)
