import React, { useEffect, useState } from 'react'
import { View, useWindowDimensions } from 'react-native'
import { Heading } from '../../Components/Typography/Typography'
import { colors } from '../../Helpers/variables'
import CarouselCards from '../../Components/Carousel/CarouselCards'

export default function NewFeedScreen({ navigation, newsFeed }) {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}
	
	return (
		<View>
			<Heading style={{ color: colors.accent, fontSize: isWideScreen ? 24 : 20 }}>Nyheder</Heading>
			<CarouselCards data={newsFeed} />
		</View>
	)
}
